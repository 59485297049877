import React, {useCallback, useContext, useEffect, useState} from "react";
import './LeftMenu.scss';
import logo from "@Assets/images/logo-menu.png";
import {useMatch, useNavigate} from "react-router-dom";
import {NavMenu, NavMenuItem} from "@Components/NavMenu";
import {motion} from "framer-motion";
import * as _ from "lodash";
import {useAuth, useUser} from "@Hooks/firebase";
import {signOut} from "firebase/auth";
import {useToast} from "@Hooks/toast";
import {AgencyRequestsContext} from "../../Contexts/AgencyRequestsContext";
import produce from "immer";
import {OrdersContext} from "../../Contexts/OrdersContext";
import {RequestsContext} from "../../Contexts/RequestsContext";

export interface Menu {
    title: string;
    items: NavMenuItem[];
}

export interface LeftMenuProps {
}

export const LeftMenu: React.FC<LeftMenuProps> = () => {

    const {agencyRequests} = useContext(AgencyRequestsContext);
    const {requests} = useContext(RequestsContext)
    const {orders} = useContext(OrdersContext);

    const {meta: user} = useUser(true);
    const auth = useAuth();
    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate('/home');
    }

    const url = useMatch("/home/:tab/*");
    const {success, error} = useToast();

    const [options, setOptions] = useState<NavMenuItem[]>([{
        icon: 'sign-out', key: 'disconnect', text: 'Déconnexion', action: async () => {
            if (auth) {
                try {
                    await signOut(auth);
                    success('Vous vous êtes correctement déconnecté !');
                } catch (e) {
                    error('Une erreur est survenue lors de la déconnection');
                }
            }
        }
    }]);

    const getOrderCount = useCallback(() => {
        return orders.filter(e => !e.status).length;
    }, [orders]);

    const getRequestCount = useCallback(() => {
        return requests.filter(e => !e.status).length;
    }, [requests])

    const [productItems, setProductItems] = useState<NavMenuItem[]>([
        {
            icon: 'pi pi-database',
            key: 'catalog',
            text: "Catalogue produit",
            action: () => navigate('/home/catalog'),
            selected: true
        },
        {
            icon: 'pi pi-chart-pie',
            key: 'conditions',
            text: "Conditions commerciales",
            action: () => navigate('/home/conditions'),
        },
        {
            icon: 'pi pi-image',
            key: 'profiles',
            text: "Profiles",
            action: () => navigate('/home/profiles'),
        },
        {
            icon: 'pi pi-file',
            key: 'orders',
            text: "Commandes",
            badgeCount: getOrderCount(),
            action: () => navigate('/home/orders'),
        },
        {
            icon: 'pi pi-sync',
            key: 'requests',
            text: "Demandes de dispo",
            badgeCount: getRequestCount(),
            action: () => navigate('/home/requests'),
        },
    ]);

    const getCount = useCallback(() => {
        return agencyRequests.length;
    }, [agencyRequests]);

    const [usersItems, setUsersItem] = useState<NavMenuItem[]>([{
        icon: 'pi pi-users',
        key: 'users',
        text: "Gestion des utilisateurs",
        badgeCount: getCount(),
        action: () => navigate('/home/users'),
    }, {
        icon: 'pi pi-building',
        key: "agencies",
        text: "Gestion des agences",
        action: () => navigate('/home/agencies'),
    }, {
        icon: 'pi pi-send',
        key: "notifications",
        text: "Notifications",
        action: () => navigate('/home/notifications'),
    },]);

    useEffect(() => {

        if (user.role && usersItems.length > 0 && productItems.length > 0) {
            const _productItems = productItems.map(e => e.key === url?.params.tab ? {...e, selected: true} : {
                ...e, selected: undefined
            });
            const _usersItems = usersItems.map(e => e.key === url?.params.tab ? {...e, selected: true} : {
                ...e, selected: undefined
            });

            const _options = options.map(e => e.key === url?.params.tab ? {...e, selected: true} : {
                ...e, selected: undefined
            });

            let productSelected = _productItems.find(item => item.selected);
            let usersSelected = _usersItems.find(item => item.selected);
            let optionSelected = _options.find(item => item.selected);

            const selected = productSelected || usersSelected || optionSelected;
            if (url?.pathname.split('/').length === 3) selected ? selected.action() : _productItems[0].action();
            else if (!url) _productItems[0].action();

            if (!_.isEqual(productItems, _productItems))
                setProductItems(_productItems);

            if (!_.isEqual(usersItems, _usersItems))
                setUsersItem(_usersItems);

            if (!_.isEqual(options, _options))
                setOptions(_options)
        }
    }, [url, user, options, productItems, usersItems]);


    useEffect(() => {
        setUsersItem(produce(draft => {
            draft[0].badgeCount = getCount();
        }));
    }, [getCount]);

    useEffect(() => {
        setProductItems(produce(draft => {
            draft[3].badgeCount = getOrderCount();
        }));
    }, [getOrderCount]);

    useEffect(() => {
        setProductItems(produce(draft => {
            draft[4].badgeCount = getRequestCount();
        }));
    }, [getRequestCount]);

    const [menuVariants] = useState({
        open: {width: "250px"}, closed: {width: "74px"}
    });

    return (<motion.div
        id="LeftMenu"
        className="h-full"
        animate={"open"}
        variants={menuVariants}
    >
        <div className="menu__inner flex flex-column h-full w-full px-3 py-4">
            <div className="flex-0 flex align-items-center justify-content-center menu__inner--logo p-3 mb-4">
                <img src={logo} alt="fps" onClick={onLogoClick} className={"cursor-pointer"}/>
            </div>
            <div className="flex-1 menu__inner__wrapper flex flex-column justify-content-start ">
                <NavMenu isOpen={true} title={"Gestion produits"} items={productItems} setItems={setProductItems}/>
                <NavMenu isOpen={true} title={"Gestion utilisateurs"} items={usersItems} setItems={setProductItems}/>
                <NavMenu isOpen={true} items={options} setItems={setOptions} className="mt-auto"/>
            </div>
        </div>
    </motion.div>)
}
