import React, {useContext, useRef} from "react";

import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";

import {Button} from "primereact/button";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {useToast} from "@Hooks/toast";
import {AgenciesContext} from "../../../../../Contexts/AgenciesContext";
import {useNavigate} from "react-router-dom";
import {Agency} from "@Types/Agency";
import {importFile} from "@Utils/import.utils";
import {classNames} from "primereact/utils";


export type AgencyListProps = {
    className?: string;
}
export const AgencyList: React.FC<AgencyListProps> = (props) => {


    const navigate = useNavigate();
    const Api = useApi();
    const {promise} = useToast();
    const {loading, loadAgencies, agencies} = useContext(AgenciesContext);

    const agencyImportRef = useRef<HTMLInputElement>(null);


    const [deleteAgency, deleteAgencyMap] = usePromise(async (agency: Agency) => {
        try {
            await Api.agency_call_deleteAgency({agency: agency});
        } catch (err) {
            console.table(err);
        } finally {
            await loadAgencies();
        }
    });

    const [agenciesImport, agenciesImportMap] = usePromise(async (f: FileList | null) => {
        if (f && f.length > 0) {
            const content = await importFile(f[0]);
            return Api.agency_call_importAgency({
                rawAgencies: content
            }).then(() => {
                return loadAgencies();
            });
        } else {
            throw new Error('No file found');
        }
    });


    return (
        <div className={classNames(['AgencyList', props.className])}>
            <input accept={'text/csv, text/tab-separated-values'} type="file" hidden
                   onChange={(f) => {
                       agenciesImport('importAgencies')(f.currentTarget.files);
                       f.target.value = ''
                   }}
                   ref={agencyImportRef}/>
            <CustomTable
                title={"Liste des agences"}
                headerSearchFields={["name", "code", "phone", "address",]}
                value={agencies}
                loading={loading}
                emptyMessage={"Aucune agence trouvée"}
                headerButton
                headerButtonLabel={"Ajouter une agence"}
                headerButtonIcon={'pi pi-plus'}
                headerButtonAction={() => navigate('/home/agencies/create')}

                headerButton2
                headerButtonLabel2="Importer liste d'agence"
                headerButtonIcon2="pi pi-plus"
                headerButtonAction2={() => agencyImportRef.current?.click()}
                headerButtonLoading2={agenciesImportMap.get('importAgencies')}

            >
                <Column header={"Nom"} sortable field="name" headerClassName="w-min"/>
                <Column header={"E-mail"} sortable field="email" headerClassName="w-min"/>

                <Column header={"Code client"} sortable field="code"/>

                <Column header={"Téléphone"} sortable field="phone"/>

                <Column header={"Adresse"} sortable field="address"/>


                <Column header={"Action"} field="status" body={(data: Agency) => {
                    return (
                        <div className={'flex align-items-center'}>
                            <Button
                                onClick={() => navigate('/home/agencies/edit/'.concat(data.agencyUid))}
                                className={'p-button-success p-button-rounded mr-3'}
                                icon={'pi pi-pencil'}/>

                            <Button
                                onClick={() => {
                                    confirmDialog({
                                        header: "Supprimer une agence ",
                                        message: "Êtes-vous sur de vouloir supprimer cette agence, cette action est définitive ?",
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: "Oui, supprimer",
                                        rejectLabel: "Annuler",
                                        rejectClassName: 'p-button-secondary',
                                        closable: false,
                                        accept: async () => {
                                            await promise(deleteAgency(data.agencyUid)(data), {
                                                pending: "Suppression de la demande en cours ...",
                                                success: "Demande supprimée !"
                                            });
                                        },
                                    });
                                }}
                                loading={deleteAgencyMap.get(data.agencyUid)}
                                className={'p-button-warning p-button-rounded'}
                                icon={'pi pi-trash'}/>
                        </div>
                    )
                }}/>

            </CustomTable>


            <ConfirmDialog/>
        </div>
    )
}