import React, {useContext, useRef, useState} from "react";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {classNames} from "primereact/utils";
import {CustomTable} from "@Components/CustomTable";
import {useApi} from "@Hooks/api";
import {ProductsContext} from "../../../../Contexts/ProductContext";
import {importFile} from "@Utils/import.utils";
import {ProductDetails} from "@Pages/Home/HomeContent/Catalog/ProductList/ProductDetails";
import {Product} from "@Types/Product";
import {usePromise} from "@Hooks/promise";
import {useToast} from "@Hooks/toast";

export type ProductListProps = {
    className?: string;
}


export const ProductList: React.FC<ProductListProps> = props => {

    const [visible, setVisible] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
    const {loading: isProductLoading, products, loadProducts} = useContext(ProductsContext);

    const catalogRef = useRef<HTMLInputElement>(null);
    const stockRef = useRef<HTMLInputElement>(null);
    const {error} = useToast();
    const Api = useApi();

    const [catalogImport, catalogImportMap] = usePromise(async (f: FileList | null) => {
        if (f && f.length > 0) {
            const content = await importFile(f[0]);
            return Api.product_call_importCatalog({
                catalog: content
            }).then(() => {
                return loadProducts();
            }).catch(err => {
                if (catalogRef.current)
                    catalogRef.current.value = '';
                error(err.message)
            });
        } else {
            throw new Error('No file found');
        }
    });
    const [stockImport, stockImportMap] = usePromise(async (f: FileList | null) => {
        if (f && f.length > 0) {
            const content = await importFile(f[0]);
            return Api.product_call_importStock({
                stock: content
            }).then(() => {
                return loadProducts();
            });
        }
        throw new Error('no file found');
    });

    const onProductImport = (f: FileList | null) => {
        catalogImport('importCatalog')(f)
    }

    const onStockImport = async (f: FileList | null) => {
        stockImport('importStock')(f);
    }


    const typeCategory = (type: string) => {
        return <Tag value={type} severity="info"/>
    }

    const onRowClick = (e: Product) => {
        setSelectedProduct(e);
        setVisible(true);
    }

    return (
        <div className={classNames(["ProductList p-card p-5", props.className])}>
            {selectedProduct && <ProductDetails visible={visible} setVisible={setVisible} product={selectedProduct}/>}
            <input type="file" hidden onChange={(f) => onProductImport(f.currentTarget.files)} ref={catalogRef}/>
            <input type="file" hidden onChange={(f) => onStockImport(f.currentTarget.files)} ref={stockRef}/>
            <CustomTable
                title={"Liste des produits"}
                headerSearchFields={["reference", "brand", "type", "meta.DES", "price", "quantity"]}
                value={products}
                loading={isProductLoading}
                headerButton
                headerButtonLabel="Importer catalogue"
                headerButtonLoading={catalogImportMap.get('importCatalog')}
                headerButtonIcon="pi pi-plus"
                headerButtonAction={() => catalogRef.current?.click()}
                headerButton2
                headerButtonLabel2="Importer le stock"
                headerButtonIcon2="pi pi-plus"
                headerButtonAction2={() => stockRef.current?.click()}
                headerButtonLoading2={stockImportMap.get('importStock')}
                emptyMessage={"Aucun produit trouvé"}
                onRowClick={e => onRowClick(e.data)}
            >
                <Column header={"Référence"} sortable field="reference" headerClassName="w-min"/>
                <Column header={"Marque"} sortable field="brand" headerClassName="w-min"/>
                <Column header={"Catégorie"} sortable sortField="type" body={data => typeCategory(data.type)}
                        headerClassName="w-min"/>
                <Column header={"Désignation"} sortable field="meta.DES"/>
                <Column header={"Prix"} sortable field="price" headerClassName="w-min"/>
                <Column header={"Qte"} sortable field="quantity"
                        body={data => data.quantity === -1 ? " - " : data.quantity} headerClassName="w-min"/>
            </CustomTable>
        </div>
    )
}
