export const ErrorMapper = (code: string) => {
    switch (code) {
        case 'auth/wrong-password':
            return "Mot de passe incorrect";
        case 'auth/user-not-found':
            return "Adresse e-mail incorrecte";
        case 'not-authorized':
            return "Opération non permise";
        default:
            return code;
    }
}

export class CustomError extends Error {
    code: string = "";
    constructor(code: string, message?: string) {
        super(message || code);
        this.code = code;
        this.message = message || ErrorMapper(code);
    }
}
