import React from "react";
import {Route, Routes} from "react-router-dom";
import {Agency} from "@Pages/Home/HomeContent/Agencies/Agency";
import {AgencyCreate} from "@Pages/Home/HomeContent/Agencies/AgencyCreate";
import {AgencyEdit} from "@Pages/Home/HomeContent/Agencies/AgencyEdit";


export const Agencies: React.FC = (props) => {

    return (
        <Routes>
            <Route path="/" element={<Agency/>}/>
            <Route path="/create" element={<AgencyCreate/>}/>
            <Route path="/edit/:id" element={<AgencyEdit/>}/>
        </Routes>
    )
}
