import React, {useEffect} from "react";
import {CustomFileInput} from "@Pages/Home/HomeContent/Conditions/components/CustomFileInput";
import {ConditionsPayload} from "@Types/Conditions";
import produce from "immer";

export type StepOneProps = {
    setConditions: React.Dispatch<React.SetStateAction<Partial<ConditionsPayload>>>
}
export const StepOne: React.FC<StepOneProps> = (props) => {

    const setValue = (field: keyof ConditionsPayload, content?: string) => produce((draft) => {
        draft[field] = content;
        return draft;
    });

    useEffect(() => () => {
        props.setConditions({})
    }, [])

    return (
        <div className="StepOne">
            <CustomFileInput
                label={"Tarifs promotionnels"}
                name={"tarifs_promo"}
                onChange={(content) => props.setConditions(setValue("tarifs_promotionnels", content))}
            />
            <CustomFileInput
                label={"Rubriques articles"}
                name={"articles"}
                onChange={(content) => props.setConditions(setValue("rubriques_articles", content))}
            />
            <CustomFileInput
                label={"Tarifs nets"}
                name={"tarifs_net"}
                onChange={(content) => props.setConditions(setValue("tarifs_nets", content))}
            />
            <CustomFileInput
                label={"Classes remise"}
                name={"discounts"}
                onChange={(content) => props.setConditions(setValue("classes_remise", content))}
            />
            <CustomFileInput
                label={"Clients"}
                name={"clients"}
                onChange={(content) => props.setConditions(setValue("clients", content))}
            />
        </div>
    )
}
