import React, {useEffect, useState} from "react";
import {LeftMenu} from "@Pages/Home/LeftMenu";
import {useResponsive} from "@Hooks/breakpoint";
import {HomeContent} from "@Pages/Home/HomeContent";
import {AgencyRequestsProvider} from "../Contexts/AgencyRequestsContext";
import {OrdersContext, OrdersProvider} from "../Contexts/OrdersContext";
import {RequestsProvider} from "../Contexts/RequestsContext";

export type HomeProps = Record<string, never>
export const Home: React.FC<HomeProps> = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);

    const getSize = (isTablet: boolean, isMedium: boolean, isLarge: boolean): string => {
        switch (true) {
            case isTablet:
                return "tablet";
            case isMedium:
                return "medium";
            case isLarge:
                return "large";
            default:
                return "large"
        }
    }

    const {isTablet, isMedium, isLarge} = useResponsive();
    const [oldSize, setOldSize] = useState<string>(getSize(isTablet, isMedium, isLarge));

    useEffect(() => {
        if (oldSize === "large" && (isTablet || isMedium)) {
            setIsMenuOpen(false);
        } else if (oldSize === "medium" && isTablet) {
            setIsMenuOpen(false);
        } else if (oldSize === "tablet" && (isMedium || isLarge)) {
            setIsMenuOpen(true);
        } else if (oldSize === "medium" && isLarge) {
            setIsMenuOpen(true);
        }

        setOldSize(getSize(isTablet, isMedium, isLarge))
    }, [isTablet, isLarge, isMedium, oldSize])

    return (
        <AgencyRequestsProvider>
            <OrdersProvider>
                <RequestsProvider>
                    <div id="Home" className="flex h-full w-full">
                        <LeftMenu/>
                        <HomeContent isOpen={isMenuOpen}/>
                    </div>
                </RequestsProvider>
            </OrdersProvider>
        </AgencyRequestsProvider>
    )
}
