import React, {useCallback, useContext, useMemo, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {NotificationsContext} from "../../../../Contexts/NotificationsContext";
import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Notification} from "@Types/Notification";
import {usePromise} from "@Hooks/promise";
import {classNames} from "primereact/utils";
import {CreateNotification} from "@Pages/Home/HomeContent/Notifications/NotificationsList/CreateNotification";
import {UpadteNotification} from "@Pages/Home/HomeContent/Notifications/NotificationsList/UpdateNotification";


export type NotificationsListProps = {
    className?: string
}

type NotificationExtended = Notification & { nombre_user: number, group_flatten: string }
export const NotificationsList: React.FC<NotificationsListProps> = (props) => {

    const Api = useApi();
    const {promise} = useToast();

    const {loading, loadNotifications, notifications, users} = useContext(NotificationsContext);

    const [visible, setVisible] = useState<'create' | 'update' | null>(null);

    const [selectedNotificationUid, setSelectedNotificationUid] = useState<string | null>(null);


    const [deleteNotification, deleteNotificationMap] = usePromise(async (notification: Notification) => {
        try {
            await Api.notification_call_deleteNotification({notification: notification});
        } catch (err) {
            console.table(err);
        } finally {
            await loadNotifications();
        }
    });

    const groups = useMemo(() => {
        return Array.from(new Set(users.map(e => e.groups).flat()))
    }, [users]);


    const loadData = (): NotificationExtended[] => {
        return notifications.map((notif) => {
            let group = notif.groups.length > 0 ? notif.groups.join(', ') : '-';
            group = group.length > 25 ? group.substring(0, 25).concat(' ...') : group;
            const users_of_group = users.filter(u => u.groups.some(user_group => notif.groups.includes(user_group)))
            return {
                ...notif,
                group_flatten: group,
                nombre_user: users_of_group.length
            }

        })
    }

    return (
        <div className={classNames(['NotificationsList', props.className])}>

            <CustomTable
                title={"Liste des notifications"}
                headerSearchFields={["title", "description"]}
                value={loadData()}
                loading={loading}
                headerButton
                headerButtonIcon={'pi pi-plus'}
                headerButtonLabel={'Nouvelle notification'}
                headerButtonAction={() => setVisible("create")}
                emptyMessage={"Aucune notification trouvée"}

            >
                <Column style={{minWidth: '25rem'}} header={"Objet"} sortable field="title" headerClassName="w-min"/>


                <Column header={"Groupe"} sortable field="group_flatten" headerClassName="w-min"/>

                <Column header={"Nombre d'utilisateur"} sortable field="nombre_user" headerClassName="w-min"/>


                <Column header={"Statut"} sortable field="status"
                        body={(data: Notification) =>
                            <Tag className={'uppercase'} severity={data.status === "sent" ? 'success' : 'danger'}>
                                {data.status === "sent" ? 'envoyé' : 'brouillon'}
                            </Tag>
                        }
                />


                <Column header={"Action"} field="status" body={(data: Notification) => {
                    return (
                        <div className={'flex align-items-center'}>
                            <Button
                                onClick={() => {
                                    setSelectedNotificationUid(data.notificationsUid);
                                    setVisible("update");
                                }}
                                className={'p-button-success p-button-rounded mr-3'}
                                icon={'pi pi-pencil'}/>

                            <Button
                                onClick={() => {
                                    confirmDialog({
                                        header: "Supprimer une notification ",
                                        message: "Êtes-vous sur de vouloir supprimer la notification, cette action est définitive ?",
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: "Oui, supprimer",
                                        rejectLabel: "Annuler",
                                        rejectClassName: 'p-button-secondary',
                                        closable: false,
                                        accept: async () => {
                                            await promise(deleteNotification(data.notificationsUid)(data), {
                                                pending: "Suppression de la notification en cours ...",
                                                success: "Notification supprimée !"
                                            });
                                        },
                                    });
                                }}
                                loading={deleteNotificationMap.get(data.notificationsUid)}
                                className={'p-button-warning p-button-rounded'}
                                icon={'pi pi-trash'}/>
                        </div>
                    )
                }}/>
            </CustomTable>

            {visible === 'create' &&
                <CreateNotification
                    groups={groups}
                    visible={visible === 'create'}
                    onHide={() => setVisible(null)}
                />
            }

            {visible === 'update' &&
                <UpadteNotification
                    groups={groups}
                    notification={notifications.find(n => n.notificationsUid === selectedNotificationUid)!}
                    visible={visible === 'update'}
                    onHide={() => setVisible(null)}
                />
            }


            <ConfirmDialog/>
        </div>

    )
}