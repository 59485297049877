import React from "react";
import {RequestList} from "@Pages/Home/HomeContent/Requests/RequestList";
import {RequestsProvider} from "../../../Contexts/RequestsContext";
import {Card} from "primereact/card";


export const Request: React.FC = (props) => {

    return (
        <div>
            <div className="Requests">
                <div className="fps__text--title mb-3">Demande de disponibilité</div>
                <div className="fps__text--description mb-5">Vous retrouvez ici la liste des demandes de
                    disponiblité
                    qui ont été faites.
                </div>

                <Card>
                    <RequestList className={'p-2'}/>
                </Card>
            </div>
        </div>
    )
}
