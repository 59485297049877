import React, {useContext} from "react";
import {UserList} from "@Pages/Home/HomeContent/Users/UserList";
import {UsersProvider} from "../../../Contexts/UsersContext";
import {TabPanel, TabView} from "primereact/tabview";
import {Card} from "primereact/card";
import {AgencyRequestList} from "@Pages/Home/HomeContent/Users/AgencyRequestList";
import {Badge} from "primereact/badge";
import {AgencyRequestsContext} from "../../../Contexts/AgencyRequestsContext";

export type UsersProps = Record<string, never>
export const Users: React.FC<UsersProps> = () => {

    const {agencyRequests} = useContext(AgencyRequestsContext);

    return (
        <UsersProvider>
                <div className="Users">
                    <div className="fps__text--title mb-3">Gestion des utilisateurs</div>
                    <div className="fps__text--description mb-5">
                        Vous retrouvez sur le tableau des utilisateurs qui ont été ajouté sur la plateforme
                    </div>
                    <Card>
                        <TabView>
                            <TabPanel header={"Liste des utilisteurs"}>
                                <UserList/>
                            </TabPanel>
                            <TabPanel header={`Liste demande agence ( ${agencyRequests.length} )`}>
                                <AgencyRequestList/>
                            </TabPanel>
                        </TabView>

                    </Card>

                </div>
        </UsersProvider>
    )
}
