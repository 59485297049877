import React, { useState } from "react";
import { Profile } from "@Types/Profile";

import { CustomTable } from "@Components/CustomTable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProfileDetail } from "@Pages/Home/HomeContent/Profiles/ProfilesList/ProfilesDataTable/ProfileDetail";
import { DateTime } from "luxon";


export type ProfilesDataTableProps = {
    title: string
    loading: boolean
    profiles: Profile[]
    loadProfiles: () => Promise<void>
    className?: string;
}
export const ProfilesDataTable: React.FC<ProfilesDataTableProps> = (props) => {


    const [visible, setVisible] = useState<boolean>(false);

    const [selectedProfileUid, setSelectedProfileUid] = useState<string | null>(null);

    return (
        <div className={'ProfilesDataTableProps'}>
            <CustomTable
                headerSearchFields={['name']}
                title={props.title}
                value={props.profiles}
                loading={props.loading}
                emptyMessage={"Aucun profil trouvé"}
            >
                <Column style={{ minWidth: '80%' }} header={"Nom du profil"} sortable field="name"
                    headerClassName="w-min" />
                <Column sortable sortField="file_metadata.updated_at" header="Dernière modification" body={data => {
                    return data.url ? DateTime.fromISO(data.file_metadata.updated_at).toFormat('dd/MM/yy - HH:mm') : '-'
                }} />

                <Column header={"Action"} field="status" headerClassName={"flex justify-content-center "}
                    body={(data: Profile) => {
                        return (
                            <div className={'flex align-items-center  justify-content-center'}>
                                <Button
                                    onClick={() => {
                                        setSelectedProfileUid(data.name);
                                        setVisible(true);
                                    }}
                                    className={'p-button-success p-button-rounded mr-3'}
                                    icon={'pi pi-pencil'} />
                            </div>
                        )
                    }} />

            </CustomTable>

            {visible &&
                <ProfileDetail visible={visible}
                    setVisible={setVisible}
                    profile={props.profiles.find(profile => profile.name === selectedProfileUid)!}
                    onHide={() => {
                        setVisible(false)
                        setSelectedProfileUid(null);
                    }}
                />
            }


        </div>
    )
}