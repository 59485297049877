import React from "react";
import './HomeContent.scss';
import {Route, Routes} from "react-router-dom";
import {classNames} from "primereact/utils";
import {motion} from "framer-motion";
import {Users} from "@Pages/Home/HomeContent/Users";
import {Catalog} from "@Pages/Home/HomeContent/Catalog";
import {Request} from "@Pages/Home/HomeContent/Request";
import {Agencies} from "@Pages/Home/HomeContent/Agencies";
import {AgenciesProvider} from "../../Contexts/AgenciesContext";
import {Orders} from "@Pages/Home/HomeContent/Orders";
import {Profiles} from "@Pages/Home/HomeContent/Profiles";
import {Conditions} from "@Pages/Home/HomeContent/Conditions";
import {ConditionsContext, ConditionsProvider} from "../../Contexts/ConditionsContext";
import {Notifications} from "@Pages/Home/HomeContent/Notifications";


export interface HomeContentProps {
    readonly isOpen: boolean;
}

export const HomeContent: React.FC<HomeContentProps> = ({isOpen}) => {

    const homeVariant = {
        open: {width: "calc(100% - 74px)"},
        closed: {width: "calc(100% - 250px)"}
    }

    return (
        <motion.div animate={isOpen ? "closed" : 'open'} variants={homeVariant}
                    className={classNames(["HomeContent", isOpen ? "HomeContent-reduced" : "HomeContent-expanded"])}>
            <div className="HomeContent--wrapper">
                <ConditionsProvider>
                    <AgenciesProvider>
                        <Routes>
                            <Route path="users/*" element={<Users/>}/>
                            <Route path="catalog/*" element={<Catalog/>}/>
                            <Route path="requests/*" element={<Request/>}/>
                            <Route path="agencies/*" element={<Agencies/>}/>
                            <Route path="orders/*" element={<Orders/>}/>
                            <Route path="profiles/*" element={<Profiles/>}/>
                            <Route path="conditions/*" element={<Conditions />}/>
                            <Route path="notifications/*" element={<Notifications/>}/>
                        </Routes>
                    </AgenciesProvider>
                </ConditionsProvider>
            </div>
        </motion.div>
    )
}
