import React from "react";
import {ProductsProvider} from "../../../Contexts/ProductContext";
import {ProductList} from "@Pages/Home/HomeContent/Catalog/ProductList";

export type CatalogProps = Record<string, never>
export const Catalog: React.FC<CatalogProps> = () => {

    return (
        <ProductsProvider>
            <div className="Users">
                <div className="fps__text--title mb-3">Catalogue produit</div>
                <div className="fps__text--description mb-5">Date de la dernière mise à jour du catalogue:</div>
                <ProductList/>
            </div>
        </ProductsProvider>
    )
}
