import React from "react";
import {Request} from "@Types/Request";

import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {Tag} from "primereact/tag";
import {InputSwitch} from "primereact/inputswitch";

import "./RequestDetail.scss";

export type RequestDetailProps = {
    visible: boolean
    setVisible: (visible: boolean) => void
    request: Request;
    updateRequest: (request: Request) => void;
    onHide: () => void;
}

export const RequestDetail: React.FC<RequestDetailProps> = (props) => {

    return (
        <div className={'RequestDetail'}>
            <Dialog style={{width: '1000px'}}
                    header={
                        <div className={classNames('text-xl',)}>
                            <Tag className={'uppercase'} severity={props.request.status ? 'success' : 'danger'}>
                                {props.request.status ? 'traité' : 'non traité'}
                            </Tag>
                        </div>
                    }
                    visible={props.visible}
                    onHide={() => {
                        props.onHide();
                    }}
                    draggable={false}
                    closeOnEscape
                    closable
                    dismissableMask
                    contentClassName="pb-5"
            >

                <div className={'flex flex-column'}>
                    <div className={'RequestDetail-header-title'}>
                        Article #{props.request.product.reference}
                    </div>

                    <div className={"mt-3 mb-6"}>
                        <div className={'p-field flex align-items-end'}>

                            <InputSwitch id={'statusChecked'} color={"green"} checked={props.request.status}
                                         onChange={(e) => {
                                             props.updateRequest({...props.request, status: e.value})
                                         }}/>
                            <label htmlFor={"statusChecked"} className={'text-lg  ml-2 RequestDetail-header-subtitle'}>
                                {props.request.status ? '  Demande  traitée' : 'Demande non traitée'}
                            </label>
                        </div>

                        <div className={'mt-5'}>
                            <div className={'text-lg font-semibold  RequestDetail-header-subtitle mb-2'}>Nom du client </div>
                            <div className={'text-lg mb-1  RequestDetail-header-subtitle'}>{props.request.agency.name} ({props.request.agency.code}) </div>
                            <div className={'text-lg mb-1 RequestDetail-header-subtitle capitalize'}>{props.request.client.lastname} {props.request.client.firstname}</div>
                            <div className={'text-lg mb-1 RequestDetail-header-subtitle '}>{props.request.client.email}</div>
                            <div className={'text-lg mb-1 RequestDetail-header-subtitle '}>{props.request.client.phone}</div>


                        </div>

                        <div className={'mt-6'}>
                            <div
                                className="RequestDetail-body-item flex justify-content-between align-items-center pr-8">
                                <div className="RequestDetail-body-item-right ">Ref</div>
                                <div/>
                                <div className="RequestDetail-body-item-right">Designation</div>
                                <div/>
                                <div/>

                            </div>
                            <div
                                className="RequestDetail-body-itemLine flex justify-content-between align-items-center pr-8 mt-2">
                                <div
                                    className="RequestDetail-body-itemLine-left ">{props.request.product.reference} </div>
                                <div/>
                                <div className="RequestDetail-body-itemLine-left">{props.request.product.des}</div>
                                <div/>
                                <div/>
                            </div>
                        </div>

                    </div>

                </div>

            </Dialog>

        </div>
    )

}
