import React, {useContext, useMemo, useState} from "react";
import {Button} from "primereact/button";
import {ConditionsList} from "@Pages/Home/HomeContent/Conditions/ConditionsList";
import {CustomDialog} from "@Components/CustomDialog";
import { StepOne } from "./Conditions/StepOne";
import { StepTwo } from "./Conditions/StepTwo";
import type {Conditions as ConditionsModel, ConditionsPayload} from "@Types/Conditions";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {ConditionsContext} from "../../../Contexts/ConditionsContext";
import styled from "styled-components";

const errorsMap: {[k: string]: string} = {
    'conditions/client_table/global_discount_not_found': "Attention la table Client fait référence à une \"Classe de remise générale\" non trouvée dans la table \"Classe de remise\"",
    'conditions/client_table/exceptional_discount_not_found': "Attention la table Client fait référence à une \"Classe de remise excep\" non trouvée dans la table \"Classe de remise\"",
    'conditions/client_table/net_article_discount_not_found': "Attention la table Client fait référence à un \"Code Tarif HT\" non trouvé dans la table \"Tarif NET\"",
    'conditions/client_table/exceptional_article_discount_not_found': "Attention la table Client fait référence à un \"Code Tarif PROMO\" non trouvé dans la table \"Tarif PROMO\"",
    'conditions/articles/exception_discount_not_found': "Attention la table ARTICLE fait référence à une \"Classe de remise excep article\" non trouvée dans la table \"Classe de remise\"",
    "conditions/articles_discount/article_not_found": "Attention la table \"Tarif NET\" fait référence à un \"Article\" inconnu pour la plateforme.\n Merci de mettre à jour les articles dans la section Catalogue produit",
}

const ErrorMessage = styled.div`
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #667085;
    `;

export type CommercialTermsProps = Record<string, never>
export const Conditions: React.FC<CommercialTermsProps> = () => {

    const [visible, setVisible] = useState<boolean>(false);
    const [errorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const [step, setStep] = useState<number>(1);

    const {loadConditions} = useContext(ConditionsContext);

    const Api = useApi();

    const {success, error} = useToast();

    const [conditions, setConditions] = useState<Partial<ConditionsPayload>>({});
    const [warnings, setWarnings] = useState<{code: string; target: string}[]>([]);

    const [validatedConditions, setValidatedConditions] = useState<ConditionsModel>();

    const [loading, setLoading] = useState<boolean>(false);

    const isValid = useMemo(() => {
        return conditions.clients && conditions.classes_remise && conditions.tarifs_nets && conditions.tarifs_promotionnels && conditions.rubriques_articles;
    }, [conditions])

    const onImportConditions = () => {
        setLoading(true);
        if (conditions.clients && conditions.classes_remise && conditions.tarifs_nets && conditions.tarifs_promotionnels && conditions.rubriques_articles) {
            Api.conditions_call_importConditions({
                clients: conditions.clients,
                classes_remise: conditions.classes_remise,
                tarifs_nets: conditions.tarifs_nets,
                rubriques_articles: conditions.rubriques_articles,
                tarifs_promotionnels: conditions.tarifs_promotionnels
            }).then(res => {
				console.log(res);
                if (res?.result === "ok") {
                    success('Conditions commerciales validées !');
                    setStep(2);
                    setValidatedConditions(res.conditions);
                    setWarnings(res.warnings);
                }
            }).catch((err: any) => {
				console.log('test', err);
                setErrorMessage(errorsMap[err.message])
                setErrorVisible(true);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onValidationConditions = async () => {
        setLoading(true)
        Api.conditions_call_validateConditions({}).then(res => {
            if (res.result === "ok") {
                success('Conditions commerciales mises à jour !');
                loadConditions().then(() => {
                    setVisible(false);
                    setStep(1);
                })
            }
        }).catch(err => {
            error(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }


    return (
        <div>
            <CustomDialog
                visible={errorVisible}
                setVisible={setErrorVisible}
                title={"Importation impossible"}
                defaultCancel
                hideAccept
                cancelLabel="Annuler l'importation"
                width={"700px"}
            >
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </CustomDialog>
            <CustomDialog
                visible={visible}
                setVisible={setVisible}
                title="Importer conditions commerciales"
                subtitle={step === 1 ? "Etape 1/2 - Importer les fichiers CSV" : "Etape 2/2 - Vérifier les informations"}
                acceptLabel={step === 1 ? "Passer à l'étape suivante" : "Valider l'import"}
                onAccept={step === 1 ? onImportConditions : onValidationConditions}
                acceptLoading={loading}
                defaultCancel={step === 1}
                onCancel={step === 2 ? () => {
                    setStep(1)
                } : undefined}
                disabledAccept={!isValid && step === 1}
            >
                {step === 1 && <StepOne setConditions={setConditions}/>}
                {step === 2 && validatedConditions && <StepTwo warnings={warnings} conditions={validatedConditions}/>}
            </CustomDialog>
            <div className="CommercialTerms flex justify-content-between align-items-center">
                <div>
                    <div className="fps__text--title mb-3">Conditions commerciales</div>
                    <div className="fps__text--description mb-5">Date de la dernière mise à jour des conditions commerciales</div>
                </div>
                <div>
                    <Button label="Importer conditions commerciales" icon="pi pi-plus" iconPos="left" onClick={() => setVisible(true)}/>
                </div>
            </div>
            <ConditionsList />
        </div>
    )
}
