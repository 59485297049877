import React, {useCallback} from "react";
import {useFormik} from "formik";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {signInWithEmailAndPassword} from "firebase/auth";
import './LoginForm.scss';
import loginSchema from "@Schemas/login.schema";
import {useAuth} from "@Hooks/firebase";
import {useToast} from "@Hooks/toast";
import {ErrorMapper} from "@Utils/Errors";
import {useNavigate} from "react-router-dom";

const initialValues = {
    email: "",
    password: "",
};

export type LoginFormProps = Record<string, never>
export const LoginForm: React.FC<LoginFormProps> = () => {

    const navigate = useNavigate();

    const auth = useAuth();

    const toast = useToast();

    const handleSubmit = useCallback((values: typeof initialValues) => {
        if (auth)
            signInWithEmailAndPassword(auth, values.email, values.password).then(() => {
                toast.success('Vous êtes connecté')
            }).catch(err => {
                toast.error(ErrorMapper(err.code))
            })
    }, [auth]);

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: handleSubmit
    });

    function onForgotten() {
        navigate('/forgotten-password')
    }

    return (
        <div className="LoginForm flex flex-column justify-content-center px-4" style={{marginTop: "162px"}}>
            <div className="fps__text--big mb-5">
                SE CONNECTER
            </div>
            <div className="flex align-items-center w-full p-input-icon-left mb-3">
                <i className="pi pi-at fps__text--placeholder-icon"/>
                <InputText
                    id="email"
                    name="email"
                    className="w-full"
                    placeholder="Saisir adresse mail"
                    onChange={formik.handleChange}
                    value={formik.values.email}/>
            </div>
            <div className="flex align-items-center w-full p-input-icon-left mb-3">
                <i className="pi pi-lock fps__text--placeholder-icon"/>
                <InputText
                    id="password"
                    name="password"
                    type="password"
                    className="w-full"
                    placeholder="Saisir mot de passe"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onKeyDown={e => {
                        if (e.code === "NumpadEnter" || e.code === "Enter") {
                            formik.submitForm()
                        }
                    }}
                />
            </div>
            <div className="mb-5 text-right">
                <span className="fps__text--link" onClick={onForgotten}>Mot de passe oublié ?</span>
            </div>
            <Button label="SE CONNECTER" className="fps__button--normal mb-3" onClick={formik.submitForm}/>
            <div>
                {Object.entries(formik.errors).map(([k, v]) => formik.touched[k as keyof typeof formik.errors] && (
                    <div className="fps__text--small-lightred">
                        {v}
                    </div>
                ))}
            </div>
        </div>
    )
}
