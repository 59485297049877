import React, {useContext} from "react";
import {ClientEntry} from "@Types/Conditions";
import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {ConditionsContext} from "../../../../../../Contexts/ConditionsContext";

export type ClientTableProps = {
    clients: ClientEntry[];
    title: string;
}
export const ClientTable: React.FC<ClientTableProps> = props => {

    const general_discount_body = (e: ClientEntry) => <div>{e.global_discount_code ? e.global_discount_code + " %" : '-'}</div>
    const exceptional_discount_body = (e: ClientEntry) => <div>{e.exceptional_discount_code ? e.exceptional_discount_code : '-'}</div>
    const net_article_discount_body = (e: ClientEntry) => <div>{e.net_article_discount_code ? e.net_article_discount_code : '-'}</div>
    const exceptional_article_discount_body = (e: ClientEntry) => <div>{e.exceptional_article_discount_code ? e.exceptional_article_discount_code : '-'}</div>

    const {isConditionsLoading} = useContext(ConditionsContext);

    return (
        <div className="ClientTable">
            <CustomTable
                loading={isConditionsLoading}
                emptyMessage={"Pas de clients"}
                title={props.title}
                headerSearchFields={['client_code']}
                value={props.clients}>

                <Column header="Code client" field="client_code" sortable/>
                <Column header="Remise générale" body={general_discount_body} sortable sortField={"global_discount_code"} />
                <Column header="Remise exceptionnelle" body={exceptional_discount_body} sortable sortField={"exceptional_discount_code"}/>
                <Column header="Code tarif HT"  body={net_article_discount_body} sortable sortField={"net_article_discount_code"}/>
                <Column header="Code tarif Promo" sortable sortField={"exceptional_article_discount_code"} body={exceptional_article_discount_body} />
            </CustomTable>
        </div>
    )
}
