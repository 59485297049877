import React, {useCallback, useEffect, useRef, useState} from "react";
import {usePromise} from "@Hooks/promise";
import {importFile} from "@Utils/import.utils";
import {Button} from "primereact/button";
import './CustomFileInput.scss';

export type CustomFileInputProps = {
    label: string;
    name: string;
    onChange: (content: string | undefined) => void;
}
export const CustomFileInput: React.FC<CustomFileInputProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState<File>();

    const [fileImport, fileImportMap] = usePromise(async (f: FileList | null) => {
        if (f && f.length > 0) {
            const content = await importFile(f[0]);
            props.onChange(content);
            console.log('change', props.label, content);
            setFile(f[0]);
        } else {
            throw new Error('No file found');
        }
    });

    const onFileImport = (f: FileList | null) => {
        fileImport(props.name)(f);
    }

    const reset = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = '';
            props.onChange(undefined);
            setFile(undefined);
        }
    }, [inputRef]);

    // onUnmount
    useEffect(() => reset, [reset]);

    return (
        <div className="CustomFileInput w-full mb-4">
            <input type="file" hidden onChange={(f) => onFileImport(f.currentTarget.files)} ref={inputRef} accept=".csv"/>
            <Button loading={fileImportMap.get(props.name)} style={{height: 40}} label={props.label} icon="pi pi-upload" className="fps__button--secondary" onClick={() => inputRef.current?.click()}/>
            {file && <div className="CustomFileInput-meta flex w-full mt-2 align-items-center">
                <div>{file.name}</div>
                <div style={{margin: '0 50px'}}>{(file.size / 1024).toFixed(2).replace('.', ',')} Ko</div>
                <div className="flex cursor-pointer align-items-center" onClick={reset}>
                    <i className="pi pi-times-circle" />
                    <div className="underline ml-2">Supprimer </div>
                </div>
            </div>}
        </div>
    )
}
