import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Auth} from "@Pages/Auth";
import styled from "styled-components";
import {useUser} from "@Hooks/firebase";
import {Home} from "@Pages/Home";

const MobileApp = styled.div`
  min-width: 390px;
  width: 100%;
  height: 100%;
`;

export const App = () => {

    const user = useUser();

    return (
        <MobileApp>
            {
                !user?.isConnected ?
                    <Routes>
                        <Route path="/login" element={<Auth isLogin/>}/>
                        <Route path="/forgotten-password" element={<Auth isLogin={false}/>}/>
                        <Route path="/*" element={<Navigate to={"/login"}/>}/>
                    </Routes>
                    :
                    <Routes>
                        <Route path="/home/*" element={<Home/>}/>
                        <Route path="/*" element={<Navigate to={"/home"}/>}/>
                    </Routes>
            }
        </MobileApp>
    )
}
