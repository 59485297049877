import {User} from "@Types/User";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";

export interface IUsersContext {
    users: User[];
    setUsers: React.Dispatch<User[]>;
    loading: boolean;
    loadUsers: () => Promise<void>;
}

export const UsersContext = React.createContext<IUsersContext>({
    loadUsers: () => Promise.resolve(),
    setUsers: () => {
    },
    loading: false,
    users: []
});

export const UsersProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [users, setUsers] = useState<User[]>([]);
    const Api = useApi();
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback(() => {
        setLoadingStatus(true);
        return Api.user_call_getClients({}).then(res => {
            if (res.result === "ok") {
                setUsers(res.users);
            } else {
                error(res.result)
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
        }).finally(() => {
            setLoadingStatus(false)
        });
    }, []);

    useEffect(() => {
        load()
    }, [])


    return (<UsersContext.Provider value={{users, setUsers, loadUsers: load, loading: loadingStatus}}>
        {props.children}
    </UsersContext.Provider>);
}
