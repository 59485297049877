import React, {useCallback, useState} from "react";
import "./CustomSelect.scss";
import {AutoComplete, AutoCompleteChangeParams, AutoCompleteCompleteMethodParams} from "primereact/autocomplete";
import {classNames} from "primereact/utils";

export type CustomSelectProps = {
    className?: string;
    completeMethod: (query: string) => any[];
    dropdown?: boolean;
    field?: string;
    label?: string;
    multiple?: boolean;
    name?: string;
    onChange: (e: AutoCompleteChangeParams) => void;
    suggestions: any[];
    upsert?: boolean;
    itemTemplate?: (item: any) => React.ReactElement;
    value: any[];
}
export const CustomSelect: React.FC<CustomSelectProps> = props => {

    const [query, setQuery] = useState<string | undefined>()
    const [filtered, setFiltered] = useState<any[]>(props.suggestions)

    const customCompleteMethod = useCallback((event: AutoCompleteCompleteMethodParams) => {
        if (props.upsert)
            setQuery(event.query);
        setFiltered(props.completeMethod(event.query).filter(e => !props.value.includes(e)));
    }, [props])

    return (
        <div className={classNames(["CustomSelect", props.className])}>
            {props.label && <label htmlFor={props.name} className="block fps__text--label mb-2">
                {props.label}
            </label>}
            <AutoComplete field={props.field}
                          multiple={props.multiple || true}
                          itemTemplate={props.itemTemplate}
                          dropdown={props.dropdown}
                          value={props.value}
                          completeMethod={customCompleteMethod}
                          onChange={props.onChange}
                          suggestions={props.upsert && filtered.length == 0 ? [...filtered, query] : filtered}/>
        </div>
    )
}
