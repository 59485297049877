import React, {useContext} from "react";

import {v4} from "uuid";

import {useFormik} from "formik";

import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";

import {produce} from "immer";

import {NotificationsContext} from "../../../../../Contexts/NotificationsContext";

import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {Notification} from "@Types/Notification";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {MultiSelect} from "primereact/multiselect";


import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import "./Notification.scss";


export type CreateNotificationProps = {
    visible: boolean;
    groups: string[]
    onHide: () => void;

}


export const CreateNotification: React.FC<CreateNotificationProps> = (props) => {


    const Api = useApi();
    const {promise} = useToast();

    const {loading, loadNotifications} = useContext(NotificationsContext);

    const formik = useFormik<Notification>({
        initialValues: {
            notificationsUid: v4(),
            status: "draft",
            title: '',
            description: '',
            createdAt: new Date().toUTCString(),
            groups: []
        },
        validate(data) {
            let errors: { [key: string]: string } = {};
            if (data.title.length === 0 || data.title === '') {
                errors['title'] = 'veuillez renseignez ce champs';
            }
            if (data.description.length === 0 || data.description === '') {
                errors['description'] = 'veuillez renseignez ce champs';
            }
            return errors;
        },
        onSubmit(data) {
            const createPromise = new Promise((resolve, reject) => {
                return Api.notification_call_createNotification({
                    notification: data
                }).then((resp) => {
                    if (resp.result === 'ok') {
                        resolve('ok');
                        loadNotifications(true);
                        props.onHide();
                    }
                }).catch((e) => reject(e))
            });
            promise(createPromise, {
                pending: 'Création de la notification en cours...',
                success: 'Notification créee',
                error: 'Oups ! une erreur est survenue. Veuillez contactez le support',
            });

        }
    });

    const sendNotification = () => {
        if (formik.isValid) {
            const createPromise = new Promise((resolve, reject) => {
                return Api.notification_call_sendNotification({
                    notification: formik.values
                }).then((resp) => {
                    if (resp.result === 'ok') {
                        resolve('ok');
                        loadNotifications(true);
                        props.onHide();
                    }
                }).catch((e) => reject(e))
            });
            promise(createPromise, {
                pending: 'La notification est en cours d\'envoie ...',
                success: 'Notification envoyée',
                error: 'Oups ! une erreur est survenue. Veuillez contacter le support',
            });
        }
    }

    const isFormFieldValid = (name: keyof Notification) => !!(formik.errors[name]);

    const getFormErrorMessage = (name: keyof Omit<Notification, "groups">): string => (isFormFieldValid(name) && formik.errors[name]) || '';

    return (
        <div className={'Notification'}>
            <Dialog style={{width: '700px'}}
                    header={<div className={classNames('fps__text')}> Ajouter une notification</div>}
                    visible={props.visible} draggable={false} closeOnEscape closable
                    dismissableMask contentClassName="pb-5"
                    onHide={() => {
                        props.onHide();
                    }}
            >

                <form id={"create.notification.form"} name={"create.notification"}
                      onSubmit={formik.handleSubmit}
                      className={'flex flex-column w-full'}>

                    <div className={"p-field mb-4"}>
                        <label htmlFor={"title"} className={"block fps__text--label mb-1"}>Titre</label>
                        <InputText className={"w-full"} type={"text"} name={"title"} id={"title"}
                                   value={formik.values.title} onChange={formik.handleChange}/>
                        <small className={'block text-red-500'}>
                            {getFormErrorMessage('title')}
                        </small>
                    </div>

                    <div className={"p-field mb-4"}>
                        <label htmlFor={"description"} className={"block fps__text--label mb-1"}>Description (max 280
                            caractères)</label>
                        <InputTextarea placeholder={'Ecrire un message'} className={"w-full h-full"} maxLength={280}
                                       rows={8} name={"description"} id={"description"}
                                       value={formik.values.description} onChange={formik.handleChange}/>
                        <small className={'block text-red-500'}>
                            {getFormErrorMessage('description')}
                        </small>
                    </div>


                    <div className={"p-field mb-4 w-full"}>
                        <label htmlFor={"description"} className={"block fps__text--label mb-1"}>Groupes</label>
                        <MultiSelect
                            multiple filter
                            filterBy={'label'}
                            filterMatchMode={"contains"}
                            selectedItemTemplate={(option) => {
                                if (option) {
                                    return (
                                        <Tag
                                            onClick={(e) => {
                                                formik.setFormikState(produce(formik, draft => {
                                                    draft.values.groups = draft.values.groups.filter(gr => gr !== option);
                                                }))
                                                e.stopPropagation();

                                            }}
                                            style={{height: '25px'}} className={"flex-inline align-items-center mr-3"}
                                            severity={"danger"}>
                                            <div className={"flex w-auto align-items-center"}>
                                                <div> {option}&nbsp;</div>
                                                <i className={'pi pi-times-circle '}/>
                                            </div>
                                        </Tag>
                                    )
                                }
                                return <div style={{height: '20px'}}></div>
                            }}
                            resetFilterOnHide
                            optionLabel={'label'} optionValue={"value"}
                            emptyFilterMessage={'Aucun résultat '}
                            className={"Notification-groups w-full py-2"}
                            value={formik.values.groups}
                            options={props.groups.map(e => ({label: e, value: e}))}
                            name={'groups'} id={"groups"}
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className={"flex align-items-center justify-content-end mt-5"}>

                        <Button
                            className={'p-button p-button-outlined'}
                            disabled={!formik.isValid || !formik.dirty}
                            loading={loading}
                            label={"Sauvegarder"}/>

                        <Button
                            type={'button'}
                            onClick={sendNotification}
                            className={'p-button p-button-primary ml-3'}
                            disabled={!formik.isValid}
                            loading={loading}
                            label={"Envoyer la notification aux utilisateurs"}/>
                    </div>


                </form>


            </Dialog>

        </div>
    )
}





