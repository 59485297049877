import React, {useEffect, useMemo, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {Conditions} from "@Types/Conditions";
import {ClientTable} from "@Pages/Home/HomeContent/Conditions/components/ConditionsBrowser/ClientTable";
import {GlobalDiscountTable} from "@Pages/Home/HomeContent/Conditions/components/ConditionsBrowser/GlobalDiscountTable";
import {
    ExceptionalDiscountTable
} from "@Pages/Home/HomeContent/Conditions/components/ConditionsBrowser/ExceptionalDiscountTable";
import {
    ArticleDiscountTable
} from "@Pages/Home/HomeContent/Conditions/components/ConditionsBrowser/ArticleDiscountTable";

export type ConditionBrowserProps = {
    conditions: Conditions;
}
export const ConditionBrowser: React.FC<ConditionBrowserProps> = props => {

    const [listTabNav, setListTabNav] = useState<{title: string; table?: React.ReactElement}[]>([]);

    const ClientTableMemo = React.memo( ClientTable );
    const GlobalDiscountMemo = React.memo( GlobalDiscountTable );
    const ExceptionalDiscountMemo = React.memo( ExceptionalDiscountTable );
    const ArticleDiscountMemo = React.memo(ArticleDiscountTable);

    useEffect(() => {
        setListTabNav([
            {
                title: "Clients",
                table: <ClientTableMemo clients={props.conditions.client_entries} title={"Table clients"}/>
            },
            {
                title: "Remise générale",
                table: <GlobalDiscountMemo discounts={props.conditions.global_discounts} title={"Tableau remise générale"} />
            },
            {
                title: "Remise exceptionelle",
                table: <ExceptionalDiscountMemo discounts={props.conditions.exceptional_discounts} title={"Tableau remise exceptionnelles"} />
            },
            {
                title: "Tarif net",
                table: <ArticleDiscountMemo discounts={props.conditions.net_article_discounts} title={"Tableau tarif net"} />
            },
            {
                title: "Tarif promotionnels",
                table: <ArticleDiscountMemo discounts={props.conditions.exceptional_article_discounts} title={"Tableau tarif promotionnelle"}/>
            },
        ])
    }, [props.conditions]);

    return (
        <div className="ConditionBrowser w-full">
            <TabView>
                {listTabNav.map((tab, index) => (
                    <TabPanel key={index} header={tab.title}>
                        {tab.table}
                    </TabPanel>
                ))}
            </TabView>
        </div>
    )
}
