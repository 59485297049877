import React from "react";
import "./Input.scss";
import {classNames} from "primereact/utils";
import {Password} from "primereact/password";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {InputMask} from "primereact/inputmask";

export type InputProps = {
    type?: 'text' | 'password' | 'number';
    onChange?: (change: string | number | null) => void;
    value: string | number;
    className?: string;
    icon?: string | false;
    name: string;
    mask?: string;
    label?: string;
    invalid?: boolean;
    disabled?: boolean;
}

export const Input: React.FC<InputProps> = (rawProps) => {

    const props = {
        icon: "pi pi-user",
        type: "text",
        ...rawProps,
    }

    return (
        <div className={classNames(["CustomInput", props.className, props.invalid && "invalid"])}>
            {props.label && <label htmlFor={props.name} className="block fps__text--label mb-2">
                {props.label}
            </label>}
            {
                props.type === "text" && (props.icon ?
                    <div className={classNames(["p-inputgroup"])}>
                        {!props.mask ? <InputText
                            id={props.name}
                            name={props.name}
                            onChange={e => props.onChange?.(e.target.value)}
                            value={props.value}
                            disabled={props.disabled}
                        /> : <InputMask
                            id={props.name}
                            name={props.name}
                            onChange={e => props.onChange?.(e.target.value)}
                            value={props.value}
                            disabled={props.disabled}
                            mask={props.mask}
                        />}
                        <span className="p-inputgroup-addon">
                            <i className={props.icon}/>
                        </span>
                    </div> :
                    <div>
                        {!props.mask ? <InputText
                            id={props.name}
                            name={props.name}
                            onChange={e => props.onChange?.(e.target.value)}
                            value={props.value}
                            disabled={props.disabled}
                        /> : <InputMask
                            id={props.name}
                            name={props.name}
                            onChange={e => props.onChange?.(e.target.value)}
                            value={props.value}
                            disabled={props.disabled}
                            mask={props.mask}
                        />}
                    </div>)
            }
            {
                props.type === "password" &&
                <Password
                    value={props.value}
                    onChange={e => props.onChange?.(e.target.value)}
                    className={classNames([props.invalid && "invalid"])}
                    name={props.name}
                    id={props.name}
                    disabled={props.disabled}
                    feedback={false}
                    toggleMask
                />
            }
            {
                props.type === "number" && typeof props.value === "number" &&
                <div className={classNames(["p-inputgroup"])}>
                    <InputNumber
                        id={props.name}
                        name={props.name}
                        onValueChange={e => props.onChange?.(e.value)}
                        value={props.value}
                        mode={"decimal"}
                        disabled={props.disabled}
                        minFractionDigits={2}
                    />
                    {
                        props.icon &&
                        <span className="p-inputgroup-addon">
                            <i className={props.icon}/>
                        </span>
                    }
                </div>
            }
        </div>
    )
}
