import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {Notification} from "@Types/Notification";
import {User} from "@Types/User";


export interface INotificationContext {
    notifications: Notification [];
    users: User[]
    setNotifications: React.Dispatch<Notification[]>;
    loading: boolean;
    loadNotifications: (onlyNotification?: boolean) => Promise<void>;

}

export const NotificationsContext = React.createContext<INotificationContext>({
    loadNotifications: () => Promise.resolve(),
    setNotifications: () => {
    },
    loading: false,
    notifications: [],
    users: [],
});

export const NotificationsProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const Api = useApi();

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [clients, setClients] = useState<User[]>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback((onlyNotification = true) => {
        setLoadingStatus(true);
        const getNotification = Api.notification_call_getNotifications({}).then(res => {
            if (res.result === "ok") {
                return res.notifications;
            } else {
                error(res.result);
                return []
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
            return [];
        });

        const getClients = Api.user_call_getClients({}).then(res => {
            if (res.result === "ok") {
                return res.users;
            } else {
                error(res.result);
                return [];
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
            return [];
        })

        if (onlyNotification) {
            return Promise.all([getNotification]).then(([_notifs]) => {
                setNotifications(_notifs);
            }).finally(() => setLoadingStatus(false));
        } else {
            return Promise.all([getNotification, getClients]).then(([_notifs, _clients]) => {
                setNotifications(_notifs);
                setClients(_clients);
            }).finally(() => setLoadingStatus(false));
        }


    }, []);

    useEffect(() => {
        load(false);
    }, [])

    return (
        <NotificationsContext.Provider value={{
            loading: loadingStatus,
            loadNotifications: load,
            notifications: notifications,
            users: clients,
            setNotifications: setNotifications
        }}>
            {props.children}
        </NotificationsContext.Provider>
    )


}