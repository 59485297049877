import React, {useContext, useEffect} from "react";
import {Card} from "primereact/card";
import {ConditionBrowser} from "@Pages/Home/HomeContent/Conditions/components/ConditionBrowser";
import {ConditionsContext} from "../../../../Contexts/ConditionsContext";

export type ConditionsListProps = Record<string, never>
export const ConditionsList: React.FC<ConditionsListProps> = () => {

    const {conditions, loadConditions, isConditionsLoading} = useContext(ConditionsContext);

    useEffect(() => {
        loadConditions();
        console.log('loaded');
    }, []);

    return (
        <Card className="p-4">
            <ConditionBrowser conditions={conditions} />
        </Card>
    )
}
