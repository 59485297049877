import React, {useContext} from "react";
import {GlobalDiscount} from "@Types/Conditions";
import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {ConditionsContext} from "../../../../../../Contexts/ConditionsContext";

export type GlobgalDiscountTableProps = {
    discounts: GlobalDiscount[];
    title: string;
}
export const GlobalDiscountTable: React.FC<GlobgalDiscountTableProps> = props => {

    const discountBody = (index: number) => (e: GlobalDiscount) => <div>{e.discounts[index].value} %</div>

    const dateBody = (e: GlobalDiscount) => <div>{e.start_date || '-'}</div>

    const {isConditionsLoading} = useContext(ConditionsContext);
    return (
        <div className="GeneralDiscountTable">
            <CustomTable
                emptyMessage={"Pas de remise générale"}
                loading={isConditionsLoading}
                title={props.title}
                value={props.discounts}>
                <Column header="Classe remise client" field="code" sortable/>
                <Column header={"Taux remise 1"} body={discountBody(0)} sortable sortField="discounts.0.value"/>
                <Column header={"Taux remise 2"} body={discountBody(1)} sortable sortField="discounts.1.value"/>
                <Column header={"Taux remise 3"} body={discountBody(2)} sortable sortField="discounts.2.value"/>
                <Column header={"Date effet"} body={dateBody} sortable sortField="start_date"/>
            </CustomTable>
        </div>
    )
}
