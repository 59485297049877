import React, {useContext, useMemo} from "react";
import {ProfilesContext} from "../../../../Contexts/ProfilsContext";
import {ProfilesDataTable} from "@Pages/Home/HomeContent/Profiles/ProfilesList/ProfilesDataTable";
import {TabPanel, TabView} from "primereact/tabview";
import {Card} from "primereact/card";


export const PROFILE_ROUE_COMPLETE = "default_RCO";
export const PROFILE_ROUE_NUE = "default_RNU";
export const PROFILE_ROUE_CHAMBRE_A_AIR = "default_CHA";

export const OTHERS_LIST_PROFILES = [PROFILE_ROUE_COMPLETE, PROFILE_ROUE_NUE, PROFILE_ROUE_CHAMBRE_A_AIR];

export const ProfilesList: React.FC = (props) => {

    const {loading, loadProfiles, profiles} = useContext(ProfilesContext);


    const listTabNav = useMemo(() => {
        return [
            {
                title: "Liste des profils pneu et roue complètes",
                subtitle: "Liste des profils",
                profiles: profiles.filter(profile => !OTHERS_LIST_PROFILES.includes(profile.name))
            },
            {
                title: " Profil roue nue",
                subtitle: "Liste des profils",
                profiles: function () {
                    const roues_nue = profiles.filter(profile => profile.name === PROFILE_ROUE_NUE);
                    if (roues_nue.length === 0) {
                        return [{
                            name: PROFILE_ROUE_NUE,
                            url : '',
                            file_metadata :{
                                name : '',
                                size: '',
                                updated_at: new Date().toUTCString(),
                            }

                        }];
                    }
                    return roues_nue;
                }()
            },
            {
                title: " Profil chambre à air",
                subtitle: "Liste des profils",
                profiles: function () {
                    const roues_chambre_air = profiles.filter(profile => profile.name === PROFILE_ROUE_CHAMBRE_A_AIR);
                    if (roues_chambre_air.length === 0) {
                        return [{
                            name: PROFILE_ROUE_CHAMBRE_A_AIR,
                            url: '',
                            file_metadata :{
                                name : '',
                                size: '',
                                updated_at: new Date().toUTCString(),

                            }
                        }];
                    }
                    return roues_chambre_air;
                }()
            },

        ]
    }, [profiles])

    return (
        <Card>
            <div className={"w-full ProfilesList"}>
                <TabView>
                    {listTabNav.map((tab, index) => (
                        <TabPanel key={index} header={tab.title}>
                            <ProfilesDataTable
                                title={tab.subtitle}
                                loading={loading}
                                profiles={tab.profiles}
                                loadProfiles={loadProfiles}/>
                        </TabPanel>
                    ))}
                </TabView>
            </div>
        </Card>
    )
}
