import React, {useMemo} from "react";
import {ConditionBrowser} from "@Pages/Home/HomeContent/Conditions/components/ConditionBrowser";
import {Conditions} from "@Types/Conditions";
import {groupBy} from "lodash";
import './StepTwo.scss';

const warningMap: Record<string, string> = {
    'conditions/article_discount_client_not_found': "Les codes clients suivants n’ont pas trouvé d’utilisateur correspondant sur la plateforme :"
}

export type StepTwoProps = {
    conditions: Conditions;
    warnings: {code: string; target: string}[];
}
export const StepTwo: React.FC<StepTwoProps> = (props) => {

    const errors = useMemo(() => {
        return groupBy(props.warnings, "code");
    }, [props.conditions]);

    return (
        <div className="StepTwo w-full">
            <div className="StepTwo-warning">
	            { props.warnings.length > 0 && <>

                <div className="StepTwo-warning-title">Attention !</div>
	            {Object.entries(errors).map(([k,v]) => {
		            return <div key={k} className="StepTwo-warning-list">
			            <div className="StepTwo-warning-list-message">{warningMap[k]}</div>
			            <ul>
				            {v.map(err => {
					            return <li className="StepTwo-warning-list-target ml-2" key={err.target}>
						            • {err.target}
					            </li>
				            })}
			            </ul>
		            </div>
	            })}
	            </>
	        }
            </div>
            <ConditionBrowser conditions={props.conditions} />
        </div>
    )
}
