import React from "react";
import {Product} from "@Types/Product";
import {Dialog} from "primereact/dialog";
import {Tag} from "primereact/tag";
import './ProductDetails.scss';
import produce from "immer";

export type ProductDetailsProps = {
    visible: boolean;
    setVisible: (value: boolean) => void;
    product: Product;
}
export const ProductDetails: React.FC<ProductDetailsProps> = props => {
    const headerTemplate = <div className="ProductDetails-header flex flex-column">
        <Tag value={props.product.type} severity="info" className="w-min"/>
        <div className="ProductDetails-header-title my-3">{props.product.des}</div>
        <div className="flex w-full justify-content-start ProductDetails-header-subtitle">
            <div>Ref: {props.product.reference}</div>
            <span className="mx-4"> / </span>
            <div>Marque: {props.product.brand}</div>
            <span className="mx-4"> / </span>
            <div>Prix: {props.product.price.toFixed(2).replace('.', ',')} €</div>
            <span className="mx-4"> / </span>
            <div>Qté: {props.product.quantity === -1 ? '-' : props.product.quantity}</div>
            <span className="mx-4"> / </span>
            <div>Famille: {props.product.category}</div>
        </div>
    </div>

    const filterProductMeta = (product: Product) => {
        const entries = Object.entries(product.meta);

        const meta = produce(product.meta, draft => {
            draft["Famille Divalto"] = draft["Famille Divalto"].trim() === "EN" ? "PN" : draft["Famille Divalto"].trim();
        });
        const regex = new RegExp(`^${meta["Famille Divalto"]}_T_`, 'ig');
        return entries.filter(([k, _]) => regex.test(k.trim())).map(([k, v]) => [k.split('_').reverse()[0], v]);
    }

    return (
        <Dialog
            onHide={() => props.setVisible(false)}
            visible={props.visible}
            header={headerTemplate}
            style={{
                width: "1000px"
            }}
            draggable={false}
            closeOnEscape
            closable
            dismissableMask
            contentClassName="pb-5"
        >
            <div className="ProductDetails-body">
                {filterProductMeta(props.product).map(([k, v]) => {
                    return <div key={k}
                                className="ProductDetails-body-item flex justify-content-between align-items-center pr-8">
                        <div className="ProductDetails-body-item-left">{k} </div>
                        <div className="ProductDetails-body-item-right">{v || " - "}</div>
                    </div>
                })}
            </div>
        </Dialog>
    )
}
