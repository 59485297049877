import React from 'react';
import {createRoot} from 'react-dom/client';
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {enableMapSet} from "immer";
import {FirebaseProvider} from "./Contexts/FirebaseContext";
import LogRocket from 'logrocket';

import 'normalize.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './theme/theme.scss';
import "primereact/resources/primereact.min.css"; //core css
import 'react-toastify/dist/ReactToastify.css';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

enableMapSet();
LogRocket.init('fps-bah8j/fps');
const root = createRoot(document.getElementById('root')!);
root.render(
    <FirebaseProvider>
        <BrowserRouter>
            <div className="fps--background w-full h-full flex justify-content-center">
                <ToastContainer/>
                <App/>
            </div>
        </BrowserRouter>
    </FirebaseProvider>
)
