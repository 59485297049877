import {User} from "@Types/User";
import React, {useContext, useState} from "react";
import {usePromise} from "@Hooks/promise";
import {useToast} from "@Hooks/toast";
import {Column, ColumnBodyType} from "primereact/column";
import {UserTag} from "@Components/UserTag";
import {ConfirmPopup} from "primereact/confirmpopup";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {CustomTable} from "@Components/CustomTable";
import {Tooltip} from "primereact/tooltip";
import {UserForm} from "@Pages/Home/HomeContent/Users/UserList/UserForm";
import {UsersContext} from "../../../../Contexts/UsersContext";
import {useApi} from "@Hooks/api";

export type UserListProps = {
    className?: string;
}


export const UserList: React.FC<UserListProps> = props => {

    const [selectedUser, setSelectedUser] = useState<User>();
    const [visible, setVisible] = useState<boolean>(false);

    const {users, loadUsers, loading: isUserLoading} = useContext(UsersContext);

    const Api = useApi();

    const [deleteUser, deleteUserMap] = usePromise(async (user: User) => {
        try {
            await Api.user_call_deleteClient({userUid: user.userUid});
        } catch (err) {
            console.table(err);
        } finally {
            await loadUsers();
        }
    });

    const {promise} = useToast();

    const onUserCreate = () => {
        setSelectedUser(undefined);
        setVisible(true);
    }

    const onUserEdit = (user: User) => {
        setSelectedUser(user);
        setVisible(true);
    }

    const onUserDelete = async (user: User) => {
        await promise(deleteUser(user.userUid)(user), {
            pending: "Suppression de l'utilisateur en cours ...",
            success: "Agent supprimé !"
        });
    }

    const UserBody: ColumnBodyType = (data: User) => <UserTag full user={data}/>;

    const confirmDelete = (data: User) => {
        confirmDialog({
            header: "Supprimer un utilisateur",
            message: "Êtes-vous sûr de vouloir supprimer l'utilisateur de la plateforme ?",
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Valider la suppression",
            rejectLabel: "Annuler",
            rejectClassName: 'p-button-secondary',
            closable: false,
            accept: () => onUserDelete(data),
        });
    }

    const statusBody = (status: boolean) => {
        return <Tag value={status ? "INSCRIT" : "NON INSCRIT"} severity={status ? "success" : "danger"}/>
    }

    const codeBody = (data: User) => {
        const str = data.agencies.map(e => e.code.toUpperCase()).join(' , ');
        const elip = str.length > 15;
        return (
            <div>
                <Tooltip target={`.groups-${data.userUid}`} position="bottom"/>
                <p className={classNames(["fps__text--small", `groups-${data.userUid}`])}
                   data-pr-tooltip={str}>{str.substring(0, 15) + (elip ? '...' : '')}</p>
            </div>
        );
    }

    const groupsBody = (data: User) => {
        const str = data.groups.join(' , ');
        const elip = str.length > 15;
        return (
            <div>
                <Tooltip target={`.groups-${data.userUid}`} position="bottom"/>
                <p className={classNames(["fps__text--small", `groups-${data.userUid}`])}
                   data-pr-tooltip={str}>{str.substring(0, 15) + (elip ? '...' : '')}</p>
            </div>
        );
    }

    const actionBody: ColumnBodyType = (data: User) => {
        return <div className="flex justify-content-center align-items-center">
            {<Button icon={"pi pi-pencil"} disabled={Boolean(deleteUserMap.get(data.userUid))}
                     className="p-button-rounded p-button-success" onClick={() => onUserEdit(data)}/>}
            {<Button icon={"pi pi-trash"} loading={deleteUserMap.get(data.userUid)}
                     className="p-button-rounded p-button-warning ml-2"
                     onClick={(e) => confirmDelete(data)}/>}
        </div>
    }


    return (
        <div className={classNames(["UserList p-card ", props.className])}>
            <ConfirmPopup/>
            <ConfirmDialog/>
            <UserForm isEdit={!!selectedUser} selectedUser={selectedUser} users={users} visible={visible}
                      setVisible={setVisible}/>
            <CustomTable
                title={"Liste des utilisateurs"}
                headerSearchFields={["firstname", "lastname", 'email', 'agencies.name', 'agencies.code', 'groups']}
                value={users}
                headerButton
                headerButtonLabel={"Ajouter un utilisateur"}
                loading={isUserLoading}
                headerButtonIcon="pi pi-plus"
                headerButtonAction={onUserCreate}
                emptyMessage={"Aucun utilisateur trouvé"}
            >
                <Column header={"Nom de l'utilisateur"} body={UserBody} sortable sortField={"firstname"}/>
                <Column header={"Codes client"} field="agencies" body={codeBody} sortable/>
                <Column header={"Groupes"} field="groups" body={groupsBody} sortable/>
                <Column
                    field={'verified'}
                    header={"Statut"}
                    bodyClassName="fps__text--small"
                    body={e => statusBody(e.verified)}
                    showFilterMatchModes={false}
                    sortable
                />
                <Column header={"Action"} body={actionBody} alignHeader="center"/>
            </CustomTable>
        </div>
    )
}
