import React from "react";
import logo from "@Assets/images/logo.svg";
import styled from "styled-components";

export type PageHeaderProps = {
    left?: {
        icon: string;
        action?: () => any;
    },
    right?: {
        icon: string;
        action?: () => any;
    }
}

const IconHolder = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  i {
    font-size: 20px;
  }
`
export const PageHeader: React.FC<PageHeaderProps> = props => {

    return (
        <div className="PageHeader flex justify-content-between align-items-center p-3 bg-white w-full">
            <IconHolder>
                {props.left && <i onClick={props.left.action} className={props.left.icon}/>}
            </IconHolder>
            <img src={logo} alt={"france pneus sélection"}/>
            <IconHolder>
                {props.right && <i onClick={props.right.action} className={props.right.icon}/>}
            </IconHolder>
        </div>
    )
}
