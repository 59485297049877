export const importFile = async (file: File): Promise<string> => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('load', (e) => {
            if (e.target && typeof e.target.result === "string") {
                const uint8Array = new TextEncoder().encode(e.target.result)
                const decoder = new TextDecoder();
                resolve(decoder.decode(uint8Array))
            } else
                reject(new Error('Error loading file'))
        })

        reader.readAsText(file);
    })

}

export const getBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data)
        }
        reader.onerror = (ev) => {
            reject(ev)
        }
    })
}

