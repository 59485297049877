import React from "react";
import {classNames} from "primereact/utils";

export interface TextIconProps {
    text: string;
    icon: string;
    size?: number;
    reduced?: boolean;
    iconPos?: 'left' | 'right';
    className?: string;
    textClassName?: string;
}

export const TextIcon: React.FC<TextIconProps> = props => {
    return (<div className={classNames(["TextIcon flex align-items-center", props.className])}>
        {props.iconPos === "left" && <i style={{fontSize: props.size || 20}} className={`pi pi-${props.icon} mr-2`}/>}
        {!props.reduced &&
            <span style={{fontSize: props.size || 20}} className={props.textClassName}>{props.text}</span>}
        {(props.iconPos === "right" || props.iconPos === undefined) && <i className={`pi pi-${props.icon} ml-2`}/>}
    </div>)
}
