import React, {useContext} from "react";
import type {ExceptionalDiscount} from "@Types/Conditions";
import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {ConditionsContext} from "../../../../../../Contexts/ConditionsContext";

export type ExceptionalDiscountTableProps = {
    discounts: ExceptionalDiscount[];
    title: string;
}
export const ExceptionalDiscountTable: React.FC<ExceptionalDiscountTableProps> = props => {

    const discountBody = (index: number) => (e: ExceptionalDiscount) => <div>{e.discounts[index].value} %</div>

    const dateBody = (e: ExceptionalDiscount) => <div>{e.start_date || '-'}</div>
    const articleClassBody = (e: ExceptionalDiscount) => <div>{e.articles_exceptional_discount?.code || '-'}</div>

    const {isConditionsLoading} = useContext(ConditionsContext);

    return (
        <div className="GeneralDiscountTable">
            <CustomTable
                loading={isConditionsLoading}
                emptyMessage={"Pas de remise exceptionnelle"}
                title={props.title}
                value={props.discounts}>
                <Column header="Classe remise excep client" field="code" sortable/>
                <Column header="Classe remise excep article" body={articleClassBody} sortField="articles_exceptional_discount.code" sortable/>
                <Column header={"Taux remise 1"} body={discountBody(0)} sortable sortField="discounts.0.value"/>
                <Column header={"Taux remise 2"} body={discountBody(1)} sortable sortField="discounts.1.value"/>
                <Column header={"Taux remise 3"} body={discountBody(2)} sortable sortField="discounts.2.value"/>
                <Column header={"Date effet"} body={dateBody} sortable sortField="start_date"/>
            </CustomTable>
        </div>
    )
}
