import React, {useContext, useState} from "react";

import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {DateTime} from "luxon";
import {RequestsContext} from "../../../../Contexts/RequestsContext";
import {Request} from "@Types/Request";

import {Button} from "primereact/button";
import {RequestDetail} from "@Pages/Home/HomeContent/Requests/RequestList/RequestDetail";
import {Tag} from "primereact/tag";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {useToast} from "@Hooks/toast";
import {classNames} from "primereact/utils";


export type RequestListProps = {
    className?: string;
}
export const RequestList: React.FC<RequestListProps> = (props) => {


    const Api = useApi();
    const {promise} = useToast();
    const [visible, setVisible] = useState<boolean>(false);

    const [selectedRequestUid, setSelectedRequestUid] = useState<string | null>(null);

    const {loading, loadRequests, requests} = useContext(RequestsContext);

    const [deleteRequest, deleteRequestMap] = usePromise(async (request: Request) => {
        try {
            await Api.request_call_deleteRequest({request: request});
        } catch (err) {
            console.table(err);
        } finally {
            await loadRequests();
        }
    });

    const onUpdateRequest = async (req: Request) => {
        try {
            await Api.request_call_updateRequest({request: req});
        } catch(e) {
            console.error(e);
        }

        await loadRequests();
    }
    const [updateRequest, updateRequestMap] = usePromise(onUpdateRequest);

    return (
        <div className={classNames(['Request', props.className])}>

            <CustomTable
                title="Liste des demandes de disponibilité"
                headerSearchFields={["product.reference", "agency.code", "client.firstname", "client.lastname", "status"]}
                value={requests.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)}
                loading={loading}
                // sortField={"createdAt"}
                // sortOrder={-1}
                emptyMessage={"Aucune demande trouvée"}
            >
                <Column header={"Référence article"} sortable field="product.reference" headerClassName="w-min"/>
                <Column header={"Date"} sortable field="createdAt" headerClassName="w-min"
                        body={(data: Request) => DateTime.fromISO(data.createdAt).toFormat('dd/MM/yy')}
                />

                <Column header={"Code client"} sortable field="agency.code"/>
                <Column header={"Nom du client"} sortable field="client.lastname" headerClassName="w-min"
                        body={(data: Request) => (`${data.client.lastname}  ${data.client.firstname}`)}
                />


                <Column header={"Statut"} sortable field="status"
                        body={(data: Request) =>
                            <Tag className={'uppercase'} severity={data.status ? 'success' : 'danger'}>
                                {data.status ? 'traité' : 'non traité'}
                            </Tag>
                        }
                />


                <Column header={"Action"} field="status" body={(data: Request) => {
                    return (
                        <div className={'flex align-items-center'}>
                            <Button
                                onClick={() => {
                                    setSelectedRequestUid(data.requestUid);
                                    setVisible(true);
                                }}
                                className={'p-button-success p-button-rounded mr-3'}
                                icon={'pi pi-eye'}/>

                            <Button
                                onClick={() => {
                                    confirmDialog({
                                        header: "Supprimer une demande ",
                                        message: "Êtes-vous sur de vouloir supprimer cette demande, cette action est définitive ?",
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: "Oui, supprimer",
                                        rejectLabel: "Annuler",
                                        rejectClassName: 'p-button-secondary',
                                        closable: false,
                                        accept: async () => {
                                            await promise(deleteRequest(data.requestUid)(data), {
                                                pending: "Suppression de la demande en cours ...",
                                                success: "Demande supprimée !"
                                            });
                                        },
                                    });
                                }}
                                loading={deleteRequestMap.get(data.requestUid)}
                                className={'p-button-warning p-button-rounded'}
                                icon={'pi pi-trash'}/>
                        </div>
                    )
                }}/>

            </CustomTable>

            {visible &&
                <RequestDetail visible={visible}
                               setVisible={setVisible}
                               request={requests.find(req => req.requestUid === selectedRequestUid)!}
                               updateRequest={async (req) => {
                                   await promise(updateRequest(req.requestUid)(req), {
                                       pending: "Mise à jour du statut en cours ...",
                                       success: "Demande mise à jour !"
                                   })
                               }}
                               onHide={() => {
                                   setVisible(false);
                                   setSelectedRequestUid(null);
                               }}
                />
            }

            <ConfirmDialog/>
        </div>
    )
}
