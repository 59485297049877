import {ApiClient} from "../../../models/Helpers";
import {Functions, httpsCallable} from "firebase/functions";
import {useMemo} from "react";
import {useAuth, useFunctions} from "@Hooks/firebase";
import {useToast} from "@Hooks/toast";
import {CustomError} from "@Utils/Errors";
import {signOut} from "firebase/auth";

export const callFunction = (functions: Functions, functionName: keyof ApiClient, onError: (error: CustomError) => void) => {
    return (body: any) => httpsCallable(functions, functionName)(body).then((res: any) => {
	    console.log(res);
        if ("result" in res.data && res.data.result !== "ok") throw new CustomError(res.data.result);
        return res.data
    }).catch((err: any) => {onError(err); throw err;})
}

export const useApi = () => {

    const functions = useFunctions();
    const {error} = useToast();

    const auth = useAuth();

    return useMemo(() => {
        return new Proxy<ApiClient>({} as any, {
            get: (_target, property: keyof ApiClient) => {
                if (!functions) throw new Error('functions not initialized');
                return callFunction(functions, property, err => {
                    error(err.message);
                    if (err.code === "not-authorized" || err.code === "not-authenticated") {
                        if (auth) signOut(auth);
                    }
                })
            }
        })
    }, [functions, auth])

}
