import React, {useContext, useState} from "react";

import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {DateTime} from "luxon";

import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {useToast} from "@Hooks/toast";

import {OrdersContext} from "../../../../Contexts/OrdersContext";
import {Order} from "@Types/Order";
import {OrderDetail} from "@Pages/Home/HomeContent/Orders/OrderList/OrderDetail";
import {doc, updateDoc} from "firebase/firestore";
import {useFirestore} from "@Hooks/firebase";
import {classNames} from "primereact/utils";


export type OrderListProps = {
    className?: string;
}
export const OrderList: React.FC<OrderListProps> = (props) => {

    const Api = useApi();
    const {promise} = useToast();
    const db = useFirestore();

    const [visible, setVisible] = useState<boolean>(false);

    const [selectedOrderUid, setSelectedOrderUid] = useState<string | null>(null);

    const {loading, loadOrders, orders,} = useContext(OrdersContext);

    const {success, error} = useToast();

    const [deleteOrder, deleteOrderMap] = usePromise(async (order: Order) => {
        try {
            await Api.order_call_deleteOrder({order: order});
        } catch (err) {
            console.table(err);
        } finally {
            await loadOrders();
        }
    });

    const [archiveOrder, archiveOrderMap] = usePromise(async (order: Order) => {
        try {
            if (db) {
                const orderRef = doc(db, 'users', order.client.userUid,'orders', order.orderUid);
                await updateDoc(orderRef, {archived: true})
            }
        } catch (err) {
            console.table(err);
        } finally {
            await loadOrders();
        }
    });


    const loadData = () => {
        return orders.map(o => ({
            ...o,
            time: new Date(o.createdAt).getTime(),
            price: `${new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'})
                .format(o.items.map(item => item.product.price * item.quantity).reduce((a, acc) => a + acc, 0))} 
                `
        })).sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)
    }

    return (
        <div className={classNames(['OrderList', props.className])}>

            <CustomTable
                title={"Liste des commandes"}
                headerSearchFields={["shipping_details.reference", "time", "client.code", "client.firstname", "client.lastname", "statut"]}
                value={loadData()}
                loading={loading}
                emptyMessage={"Aucune commande trouvée"}

            >
                <Column header={"N° commande"} sortable sortField="orderUid" body={(d: Order) => "#"+d.orderUid.substring(0,6).toUpperCase()} headerClassName="w-min"/>

                <Column header={"Ref"} sortable field="shipping_details.reference" headerClassName="w-min"/>

                <Column header={"Date"} sortable field="time" headerClassName="w-min"
                        body={(data: Order) => DateTime.fromJSDate(new Date(data.createdAt)).toFormat('dd/MM/yy')}
                />

                <Column header={"Code client"} sortable field="client.selectedAgency.code" headerClassName="w-min"/>


                <Column header={"Nom du client"} sortable field="client.lastname" headerClassName="w-min"
                        body={(data: Order) => (`${data.client.lastname}  ${data.client.firstname}`)}
                />

                <Column header={"Statut"} sortable field="status"
                        body={(data: Order) =>
                            <Tag className={'uppercase'} severity={data.status ? 'success' : 'danger'}>
                                {data.status ? 'traité' : 'non traité'}
                            </Tag>
                        }
                />

                <Column header={"Prix"} sortable field="price" headerClassName="w-min"/>


                <Column header={"Action"} field="status" body={(data: Order) => {
                    return (
                        <div className={'flex align-items-center'}>
                            <Button
                                onClick={() => {
                                    setSelectedOrderUid(data.orderUid);
                                    setVisible(true);
                                }}
                                className={'p-button-success p-button-rounded mr-3'}
                                icon={'pi pi-eye'}/>

                            <Button
                                onClick={() => {
                                    confirmDialog({
                                        header: "Archive une commande ",
                                        message: "Êtes-vous sur de vouloir archiver la commande, elle n’apparaitera plus sur la plateforme ?",
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: "Archiver la commande",
                                        rejectLabel: "Annuler",
                                        rejectClassName: 'p-button-secondary',
                                        closable: false,
                                        accept: async () => {
                                            await promise(archiveOrder(data.orderUid)(data), {
                                                pending: "Archivage de la commande en cours ...",
                                                success: "Commande archivée !"
                                            });
                                        },
                                    });
                                }}
                                loading={archiveOrderMap.get(data.orderUid)}
                                className={'p-button-warning p-button-rounded mr-3'}
                                icon={'pi pi-inbox'}/>

                            <Button
                                onClick={() => {
                                    confirmDialog({
                                        header: "Supprimer une commande ",
                                        message: "Êtes-vous sur de vouloir supprimer la commande, cette action est définitive ?",
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: "Oui, supprimer",
                                        rejectLabel: "Annuler",
                                        rejectClassName: 'p-button-secondary',
                                        closable: false,
                                        accept: async () => {
                                            await promise(deleteOrder(data.orderUid)(data), {
                                                pending: "Suppression de la commande en cours ...",
                                                success: "Commande supprimée !"
                                            });
                                        },
                                    });
                                }}
                                loading={deleteOrderMap.get(data.orderUid)}
                                className={'p-button-danger p-button-rounded'}
                                icon={'pi pi-trash'}/>
                        </div>
                    )
                }}/>

            </CustomTable>

            {visible &&
                <OrderDetail visible={visible}
                             setVisible={setVisible}
                             order={orders.find(order => order.orderUid === selectedOrderUid)!}
                             updateOrder={async (order) => {
                                 if (db) {
                                     const orderRef = doc(db, 'users', order.client.userUid,'orders', order.orderUid);
                                     try {
                                         await updateDoc(orderRef, {status: true});
                                         success('Commande mise à jour');
                                     } catch (err: any) {
                                         error(err.message);
                                     } finally {
                                         await loadOrders();
                                     }
                                 }
                             }}
                             onHide={() => {
                                 setVisible(false);
                                 setSelectedOrderUid(null);
                             }}
                />

            }

            <ConfirmDialog/>
        </div>
    )
}
