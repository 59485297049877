import React, {useCallback, useEffect, useState} from "react";
import {Conditions} from "@Types/Conditions";
import {doc, getDoc} from "firebase/firestore";
import {useAuth, useFirestore} from "@Hooks/firebase";
import {signOut} from "firebase/auth";
import {useToast} from "@Hooks/toast";
import {useApi} from "@Hooks/api";

export interface ConditionsContextI {
    conditions: Conditions;
    tmpConditions: Conditions;
    loadTmpConditions: () => Promise<void>;
    loadConditions: () => Promise<void>;
    isTmpLoading: boolean,
    isConditionsLoading: boolean
}

const emptyConditions: Conditions = {
    client_entries: [],
    net_article_discounts: [],
    global_discounts: [],
    exceptional_discounts: [],
    exceptional_article_discounts: []
};
export const ConditionsContext = React.createContext<ConditionsContextI>({
    conditions: emptyConditions,
    tmpConditions: emptyConditions,
    loadConditions: () => Promise.resolve(),
    loadTmpConditions: () => Promise.resolve(),
    isConditionsLoading: false,
    isTmpLoading: false
});

export const ConditionsProvider: React.FC<React.PropsWithChildren> = props => {

    const [conditions, setConditions] = useState<Conditions>(emptyConditions);
    const [tmpConditions, setTmpConditions] = useState<Conditions>(emptyConditions);

    const [isConditionsLoading, setIsConditionsLoading] = useState<boolean>(false);
    const [isTmpLoading, setIsTmpLoading] = useState<boolean>(false);

    const db = useFirestore();
    const auth = useAuth();

    const Api = useApi()

    const loadConditions = useCallback(() => {
        setIsConditionsLoading(true);
        if (db) {
            return Api.conditions_call_getConditions({}).then(res => {
                if (res.result === "ok") {
                    setConditions(res.conditions)
                }
            }).finally(() => {
                setIsConditionsLoading(false);
            })
        }
        setIsConditionsLoading(false);
        return Promise.reject()
    }, [db, auth]);

    const loadTmpConditions = useCallback(() => {
        setIsTmpLoading(true);
        if (db) {
            const conditionsRef = doc(db, 'conditions', 'tmp');
            return getDoc(conditionsRef).then(res => {
                const data = res.data();
                if (data) {
                    setTmpConditions(data as Conditions);
                }
            }).finally(() => {
                setIsTmpLoading(false);
            })
        }
        setIsTmpLoading(false);
        return Promise.reject()
    }, [db])

    return <ConditionsContext.Provider value={{
        loadTmpConditions,
        tmpConditions,
        loadConditions,
        isConditionsLoading,
        isTmpLoading,
        conditions
    }}>
        {props.children}
    </ConditionsContext.Provider>
}
