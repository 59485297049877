import React from "react";
import "./NavMenu.scss";
import {classNames} from "primereact/utils";
import {motion} from "framer-motion";
import {TextIcon} from "@Components/TextIcon";
import {Badge} from "primereact/badge";

export interface NavMenuItem {
    text: string;
    icon: string;
    key: string;
    badgeCount?: number;
    selected?: boolean;
    action: () => void;
}

export interface NavMenuProps {
    title?: string;
    items: NavMenuItem[];
    setItems: (items: NavMenuItem[]) => void;
    isOpen: boolean;
    className?: string;
}

export const NavMenu: React.FC<NavMenuProps> = props => {

    function onItemClick(key: string) {
        props.items.filter(e => e.key === key)[0].action();
    }

    return (
        <motion.div className={classNames(["NavMenu w-full", props.className])}>
            {
                props.isOpen && props.title && <div className="NavMenu--title fps__text--bold white-space-nowrap">
                    {props.title}
                </div>
            }
            {
                props.items.map(item => (
                    <motion.div
                        key={item.key}
                        className={classNames(["NavMenu--item flex fps__text--regular cursor-pointer overflow-hidden border-round my-1", item.selected && "NavMenu--item-active"])}
                        onClick={() => onItemClick(item.key)}>
                        <TextIcon textClassName="white-space-nowrap" reduced={!props.isOpen} text={item.text}
                                  icon={item.icon} iconPos="left" size={13}/>
                        {!!item.badgeCount && item.badgeCount > 0 && <Badge value={item.badgeCount} className="ml-2"/>}
                    </motion.div>
                ))
            }
        </motion.div>
    )
}
