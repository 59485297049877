import {Product} from "@Types/Product";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useToast} from "@Hooks/toast";
import {useFirestore} from "@Hooks/firebase";
import {useApi} from "@Hooks/api";

export interface IProductsContext {
    products: Product[];
    setProducts: React.Dispatch<Product[]>;
    loading: boolean;
    loadProducts: () => Promise<void>;
}

export const ProductsContext = React.createContext<IProductsContext>({
    loadProducts: () => Promise.resolve(),
    setProducts: () => {},
    loading: false,
    products: []
});

export const ProductsProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [products, setProducts] = useState<Product[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();
    const firestore = useFirestore();

    const Api = useApi();

    const load = useCallback(() => {
        setLoadingStatus(true);
        if (firestore) {
            return Api.product_call_getProducts({}).then(res => {
                if (res.result === "ok") {
                    setProducts(res.products);
                }
            }).finally(() => {
                setLoadingStatus(false)
            })
        } else return Promise.reject('Firestore not initialized');
    }, []);

    useEffect(() => {
        void load()
    }, [])


    return (<ProductsContext.Provider
        value={{products: products, setProducts: setProducts, loadProducts: load, loading: loadingStatus}}>
        {props.children}
    </ProductsContext.Provider>);
}
