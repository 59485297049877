import React from "react";
import {ProfilesProvider} from "../../../Contexts/ProfilsContext";
import {ProfilesList} from "@Pages/Home/HomeContent/Profiles/ProfilesList";


export const Profiles: React.FC = (props) => {

    return (
        <ProfilesProvider>
            <div>
                <div className="Profiles">
                    <div className="fps__text--title mb-3">Profils</div>
                    <div className="fps__text--description mb-5">Vous pouvez ajouter une image pour chaque profils
                    </div>

                    <ProfilesList/>

                </div>
            </div>
        </ProfilesProvider>
    )
}