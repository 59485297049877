import React from "react";
import "./CustomDialog.scss";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";

export interface CustomDialogProps {
    title?: string;
    subtitle?: string;
    acceptLabel?: string;
    cancelLabel?: string;
    onAccept?: () => void;
    onCancel?: () => void;
    hideAccept?: boolean;
    hideCancel?: boolean;
    acceptLoading?: boolean;
    acceptClassName?: string
    cancelClassName?: string
    disabledAccept?: boolean;
    disabledCancel?: boolean;
    defaultCancel?: boolean;
    visible: boolean;
    customFooter?: React.ReactElement;
    setVisible: (visible: boolean) => void;
    width?: string;
}

export const CustomDialog: React.FC<React.PropsWithChildren<CustomDialogProps>> = props => {

    const defaultClose = () => {
        props.defaultCancel && props.setVisible(false);
        props.onCancel?.();
    }

    const footer = <div>
        {!props.hideCancel && <Button
            label={props.cancelLabel || "Annuler"}
            onClick={defaultClose}
            className={classNames(["p-button-secondary fps__button--normal", props.cancelClassName])}
            disabled={props.disabledCancel}/>}
        {!props.hideAccept && <Button
            label={props.acceptLabel || "Valider"}
            onClick={props.onAccept}
            loading={props.acceptLoading}
            className={classNames(["fps__button--normal mr-0", props.acceptClassName])}
            disabled={props.disabledAccept}/>}
    </div>

    const header = <div>
        <div className="fps__text--big">{props.title}</div>
        {props.subtitle && <div className="fps__text--regular text-left mt-3">{props.subtitle}</div>}
    </div>

    return (
        <Dialog
            className="CustomDialog"
            style={{width: props.width || "900px"}}
            header={header}
            onHide={() => props.setVisible(false)}
            visible={props.visible}
            closable={false}
            draggable={false}
            footer={props.customFooter || footer}
        >
            {props.children}
        </Dialog>
    )
}
