import React from "react";
import "./UserTag.scss";
import avatarImage from "@Assets/images/user-avatar.svg";

export interface UserTagProps {
    user: {
        firstname: string;
        lastname: string;
        email: string;
    };
    full?: boolean;
}

export const UserTag: React.FC<UserTagProps> = props => {

    return (
        <div className="UserTag flex" style={{height: "32px"}}>
            <div className="UserTag__logo mr-3">
                <img src={avatarImage}/>
            </div>
            <div className="UserTag__information flex flex-column justify-content-center align-items-start">
                <div className="UserTag__information--name fps__text--small">
                    {props.user.lastname} {props.user.firstname}
                </div>
                {props.full &&
                    <div className="UserTag__information--phone fps__text--small mt-1" style={{color: "#71717A"}}>
                        {props.user.email}
                    </div>}
            </div>
        </div>
    )
}
