import React, {useContext} from "react";
import {ArticleDiscount} from "@Types/Conditions";
import {CustomTable} from "@Components/CustomTable";
import {Column} from "primereact/column";
import {ConditionsContext} from "../../../../../../Contexts/ConditionsContext";

export type GlobgalDiscountTableProps = {
    discounts: ArticleDiscount[];
    title: string;
}
export const ArticleDiscountTable: React.FC<GlobgalDiscountTableProps> = props => {

    const dateStartBody = (e: ArticleDiscount) => <div>{e.start_date || '-'}</div>
    const dateEndBody = (e: ArticleDiscount) => <div>{e.end_date || '-'}</div>
    const clientBody = (e: ArticleDiscount) => <div>{e.client_code || '-'}</div>
    const priceBody = (e: ArticleDiscount) => <div>{e.price} €</div>

    const {isConditionsLoading} = useContext(ConditionsContext);

    return (
        <div className="GeneralDiscountTable">
            <CustomTable
                emptyMessage={"Pas de tarif disponible"}
                loading={isConditionsLoading}
                title={props.title}
                headerSearchFields={['client_code', 'code', 'reference']}
                value={props.discounts}>
                <Column header="Code client" sortField="client_code" body={clientBody} sortable/>
                <Column header="Code tarif" field="code" sortable/>
                <Column header="Code article" field="reference" sortable/>
                <Column header="Tarif" sortField="price" body={priceBody} sortable/>
                <Column header={"Date effet"} body={dateStartBody} sortable sortField="start_date"/>
                <Column header={"Date de fin"} body={dateEndBody} sortable sortField="end_date"/>
            </CustomTable>
        </div>
    )
}
