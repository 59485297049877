import React, {useContext} from "react";
import {useFormik} from "formik";
import {v4} from "uuid";

import {useNavigate} from "react-router-dom";

import {Agency} from "@Types/Agency";
import validator from "validator";

import {Button} from "primereact/button";
import {Card} from "primereact/card";

import {InputText} from "primereact/inputtext";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {AgenciesContext} from "../../../../Contexts/AgenciesContext";


export type AgencyCreateProps = {}

export const AgencyCreate: React.FC<AgencyCreateProps> = (props) => {

    const navigate = useNavigate();


    const {loadAgencies, loading} = useContext(AgenciesContext);

    const Api = useApi();

    const {promise} = useToast();


    const formik = useFormik<Agency>({
        initialValues: {
            agencyUid: v4(),
            name: '',
            code: '',
            email: '',
            phone: '',
            address: '',
        },
        validate(data) {
            let errors: { [key: string]: string } = {};
            ['name', 'code'].forEach((property) => {
                if (data[property as keyof Agency] === '') {
                    errors[property] = 'veuillez renseignez ce champs'
                }
            });
            if (data.email && data.email.length > 0 && !validator.isEmail(data.email)) {
                errors['email'] = 'adresse e-mail invalide'
            }
            return errors;
        },
        onSubmit(data) {
            const _promise = new Promise((resolve, reject) => {
                Api.agency_call_createAgency({agency: data}).then((response) => {
                    if (response.result === "ok") {
                        loadAgencies();
                        resolve('ok');
                    }
                }).catch((e) => reject(e));
            });

            promise(_promise, {
                pending: "Création de l'agence en cours ...",
                success: "Agence créée !"
            });

        }
    });


    const isFormFieldValid = (name: keyof Agency) => !!(formik.errors[name]);

    const getFormErrorMessage = (name: keyof Agency): string => isFormFieldValid(name) && formik.errors[name] || '';


    return (
        <div className={'w-full flex flex-column'}>

            <form id={"agency.create"} name={"agency.create"} onSubmit={formik.handleSubmit}>
                <div
                    onClick={() => navigate('/home/agencies')}
                    className={"w-min cursor-pointer flex align-items-center text-red-700 font-semibold"}>
                    <i className={'pi pi-angle-left '}/>&nbsp;&nbsp;Retour
                </div>

                <div className={"w-full flex align-items-start justify-content-between mt-5"}>
                    <div className="fps__text--title-medium-bold mb-3">Agence</div>

                    <Button
                        loading={loading}
                        disabled={!formik.isValid && formik.dirty}
                        type={'submit'}
                        icon={'pi pi-save'}
                        label={'Sauvegarder les modifications'}/>


                </div>


                <Card className={'mt-5'}>

                    <div className={"p-field mb-3"}>
                        <label htmlFor={"name"} className={"block fps__text--label"}>Nom de l'agence</label>
                        <InputText className={"w-full"} type={"text"} name={"name"} id={"name"}
                                   value={formik.values.name} onChange={formik.handleChange}/>
                        <small className={'block text-red-500'}>
                            {getFormErrorMessage('name')}
                        </small>
                    </div>

                    <div className={"p-field mb-3"}>
                        <label htmlFor={"address"} className={"block fps__text--label"}>Adresse postal</label>
                        <InputText className={"w-full"} type={"text"} name={"address"} id={"address"}
                                   value={formik.values.address} onChange={formik.handleChange}/>
                    </div>

                    <div className={"flex justify-content-between mb-3"}>
                        <div className={"p-field w-6 pr-2"}>
                            <label htmlFor={"e-mail"} className={"block fps__text--label"}>Adresse e-mail</label>
                            <div className={'p-input-icon-left w-full'}>
                                <i className="pi pi-at fps__text--placeholder-icon"/>
                                <InputText className={"w-full"} type={"email"} name={"email"} id={"email"}
                                           value={formik.values.email} onChange={formik.handleChange}/>
                            </div>
                            <small className={'block text-red-500'}>
                                {getFormErrorMessage('email')}
                            </small>
                        </div>

                        <div className={"p-field w-6 pl-2"}>
                            <label htmlFor={"phone"} className={"block fps__text--label"}>Telephone</label>
                            <div className={'p-input-icon-left w-full'}>
                                <i className="pi pi-phone fps__text--placeholder-icon"/>
                                <InputText className={"w-full"} type={"tel"} name={"phone"} id={"phone"}
                                           value={formik.values.phone} onChange={formik.handleChange}/>
                            </div>
                        </div>
                    </div>

                    <div className={"p-field mb-3"}>
                        <label htmlFor={"code_client"} className={"block fps__text--label"}>Code client</label>
                        <div className={'p-input-icon-left w-full'}>
                            <i className="pi pi-search fps__text--placeholder-icon"/>
                            <InputText className={"w-full"} type={"text"} name={"code"} id={"code"}
                                       value={formik.values.code} onChange={formik.handleChange}/>
                        </div>
                        <small className={'block text-red-500'}>
                            {getFormErrorMessage('code')}
                        </small>
                    </div>


                </Card>
            </form>


        </div>
    )
}
