import React from "react";
import {NotificationsProvider} from "../../../Contexts/NotificationsContext";
import {NotificationsList} from "@Pages/Home/HomeContent/Notifications/NotificationsList";
import {Card} from "primereact/card";


export const Notifications: React.FC = (props) => {


    return (
        <NotificationsProvider>
            <div className="Notifications">
                <div className="fps__text--title mb-3">Notifications</div>
                <div className="fps__text--description mb-5">Vous retrouvez sur le tableau des notifications qui ont été
                    envoyées ou créées.
                </div>

                <Card>
                    <NotificationsList className={'p-2'}/>
                </Card>


            </div>
        </NotificationsProvider>
    )
}