import React, {Ref, useContext, useEffect, useRef, useState} from "react";

import {Dialog} from "primereact/dialog";
import {Profile} from "@Types/Profile";
import {getDownloadURL, ref, uploadBytes, deleteObject} from 'firebase/storage'


import {ProfilesContext} from "../../../../../../Contexts/ProfilsContext";
import {AnimatePresence, motion} from "framer-motion";

import {useStorage} from "@Hooks/firebase";
import {useApi} from "@Hooks/api";


import {Skeleton} from "primereact/skeleton";
import {Input} from "@Components/Input";
import {useToast} from "@Hooks/toast";
import {getBase64} from "@Utils/import.utils";
import {Button} from "primereact/button";
import {DateTime} from "luxon";

import "./ProfileDetail.scss";

export type ProfileDetailProps = {
    visible: boolean
    setVisible: (visible: boolean) => void
    profile: Profile;
    onHide: () => void;
}

const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const ProfileDetail: React.FC<ProfileDetailProps> = (props) => {

    const Api = useApi();
    const storage = useStorage()!;

    const {error, promise} = useToast();


    const [filePreview, setFilePreview] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const [loadingImage, setLoadingImage] = useState<boolean>(true);

    const {loadProfiles} = useContext(ProfilesContext);

    const uploadRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (props.profile.url && props.profile.url.length > 0) {
            getDownloadURL(ref(storage, props.profile.url)).then((url) => {
                setFilePreview(url);
                setLoadingImage(false);
            })
        } else {
            setLoadingImage(false);
        }

    }, [props.profile, storage]);


    const handleSave = (): void => {
        if (file) {
            const profileRef = `profiles/${props.profile.name}.${file.type.split('/').reverse().at(0)}`;
            const updateProfile: Profile = {
                ...props.profile,
                url: profileRef,
                file_metadata: {
                    name: file.name,
                    size: `${(file.size / 1000).toFixed(1)} Ko`,
                    updated_at: new Date().toISOString(),
                }
            }
            const uploadPromise = new Promise((resolve, reject) => {
                return uploadBytes(ref(storage, profileRef), file).then(() => {
                    return Api.profile_call_updateProfile({
                        profile: updateProfile
                    }).then((response) => {
                        if (response.result === 'ok') {
                            loadProfiles();
                            resolve('ok');
                            props.onHide();
                        }
                    }).catch((e) => reject(e));
                }).catch((e) => reject(e));
            });
            promise(uploadPromise, {
                error: 'Oups ! une erreur est survenue veuillez contactez le service support',
                pending: 'Le fichier est en cours de téléchargement...',
                success: 'Profil mis à jour.'
            });
        } else {
            if (props.profile.url) {
                const deletePromise = new Promise((resolve, reject) => {
                    deleteObject(ref(storage, props.profile.url)).then(() => {
                        const updateProfile: Profile = {
                            ...props.profile,
                            url: '',
                            file_metadata: {name: '', updated_at: new Date().toUTCString(), size: ''}
                        }
                        return Api.profile_call_updateProfile({
                            profile: updateProfile
                        }).then((response) => {
                            if (response.result === 'ok') {
                                loadProfiles();
                                resolve('ok');
                                props.onHide();
                            }
                        }).catch((e) => reject(e));
                    }).catch((e) => reject(e));
                });

                promise(deletePromise, {
                    error: 'Oups ! une erreur est survenue, veuillez contactez le service support',
                    pending: 'Le fichier est suppression ...',
                    success: 'Profil mis à jour.'
                });



            }

        }


    }


    return (
        <div className={'ProfileDetail'}>
            <Dialog style={{width: '1000px'}}
                    header={<div className={"ProfileDetail-header-title"}>Ajouter une image</div>}
                    visible={props.visible} draggable={false}
                    closeOnEscape closable dismissableMask
                    contentClassName="pb-5"
                    onHide={() => {
                        props.onHide();
                    }}>

                {!loadingImage ?
                    <>
                        <div onClick={(e) => {
                            uploadRef.current?.click();
                        }} className={'flex flex-column cursor-pointer'}>
                            <Input label={'Importer une image'} className={'cursor-pointer'} icon={'pi pi-download'}
                                   value={
                                       filePreview ?
                                           file ? file.name :
                                               props.profile.file_metadata ? props.profile.file_metadata.name : ''
                                           : ''
                                   }
                                   name={'upload_img_profile'}
                            />

                            <input accept={'image/jpg, image/jpeg, image/png, image/gif'}
                                   onChange={(e) => {
                                       if (e.target.files) {
                                           const file = e.target.files[0];
                                           if (!['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
                                               error('Veuillez une image de type png, jpeg, jpg');
                                               return;
                                           }
                                           if (file.size >= MAX_FILE_SIZE) {
                                               error("Taille de l'image trop grande");
                                               return;
                                           }
                                           getBase64(file).then((base64) => {
                                               setFile(file);
                                               setFilePreview(base64);
                                           })
                                       }
                                   }}
                                   className={'hidden'} size={MAX_FILE_SIZE} ref={uploadRef as Ref<HTMLInputElement>}
                                   type={'file'}/>
                        </div>

                        <div className={'flex align-items-baseline mt-2 ProfileDetail-header-subtitle'}>
                            <i className={'pi pi-exclamation-circle'}/>
                            <span>&nbsp;Taille de l'image à ne pas dépasser : 2 Mo</span>
                        </div>

                        <AnimatePresence>
                            {filePreview &&
                                <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    className={'flex align-items-center justify-content-between mt-5'}>
                                    <div className={"flex align-items-center "}>
                                        <img alt={"img_profile"} style={{maxHeight: '100px', objectFit: 'contain'}}
                                             src={filePreview}/>

                                        <div className={'flex flex-column ml-4'}>
                                            <div
                                                className={'ProfileDetail-header-subtitle__black'}>{
                                                file ? file.name :
                                                    props.profile.file_metadata?.name}</div>
                                            <div
                                                className={'mt-3  ProfileDetail-header-subtitle'}>{props.profile.file_metadata?.updated_at ?
                                                DateTime.fromJSDate(new Date(props.profile.file_metadata.updated_at)).toFormat('dd/MM/yyyy') :
                                                DateTime.now().setZone("Europe/Paris").toFormat('dd/MM/yyyy')
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    <Button
                                        label={props.profile.file_metadata?.size || `${file ? (file.size / 1000).toFixed(1) : ''} Ko`}
                                        className={'p-button p-button-sm  ProfileDetail--btn-secondary'}
                                    />

                                    <Button
                                        icon={'pi pi-times'}
                                        className={"p-button p-button-outlined p-button-rounded p-button-primary"}
                                        onClick={() => {
                                            setFile(null);
                                            setFilePreview(null)
                                        }}
                                    />
                                </motion.div>
                            }
                        </AnimatePresence>


                        <div className={'flex align-items-center justify-content-end'}>
                            <Button className={'p-button p-button-outlined'}
                                    onClick={props.onHide}
                                    label={'Annuler'}/>

                            <Button className={'p-button  ml-3'}
                                    onClick={handleSave}
                                    label={"Enregistrer l'image "}/>
                        </div>
                    </> :
                    <div className="field w-full">
                        <Skeleton className="mb-2"/>
                        <Skeleton width="30rem" className="mb-2 w-full"/>
                        <Skeleton height="2rem" className="mb-2 w-full"/>
                        <div className={"flex justify-content-between w-full"}>
                            <Skeleton width="10rem" height="4rem"/>
                            <Skeleton shape={"circle"} size={'4rem'}/>
                        </div>

                    </div>
                }


            </Dialog>

        </div>
    )

}
