import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {AgencyRequest} from "@Types/AgencyRequest";


export interface IAgencyRequest {
    agencyRequests: AgencyRequest [];
    loading: boolean;
    loadAgencyRequests: () => Promise<void>;

}

export const AgencyRequestsContext = React.createContext<IAgencyRequest>({
    agencyRequests: [],
    loadAgencyRequests: () => Promise.resolve(),
    loading: false,
});

export const AgencyRequestsProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const Api = useApi();

    const [agencyRequests, setAgencyRequests] = useState<AgencyRequest[]>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback(() => {
        setLoadingStatus(true);
        return Api.agencyrequest_call_getAgencyRequest({}).then(res => {
            if (res.result === "ok") {
                setAgencyRequests(res.agencyRequests);
            } else {
                error(res.result);
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
        }).finally(() => {
            setLoadingStatus(false);
        });
    }, []);

    useEffect(() => {
        void load();
    }, [])

    return (
        <AgencyRequestsContext.Provider value={{
            loading: loadingStatus,
            loadAgencyRequests: load,
            agencyRequests: agencyRequests,
        }}>
            {props.children}
        </AgencyRequestsContext.Provider>
    )


}
