import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {Request} from "@Types/Request";


export interface IRequestsContext {
    requests: Request [];
    setRequests: React.Dispatch<Request[]>;
    loading: boolean;
    loadRequests: () => Promise<void>;

}

export const RequestsContext = React.createContext<IRequestsContext>({
    loadRequests: () => Promise.resolve(),
    setRequests: () => {
    },
    loading: false,
    requests: []
});

export const RequestsProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const Api = useApi();

    const [requests, setRequests] = useState<Request[]>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback(() => {
        setLoadingStatus(true);
        return Api.request_call_getRequests({}).then(res => {
            if (res.result === "ok") {
                setRequests(res.requests);
            } else {
                error(res.result);
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
        }).finally(() => {
            setLoadingStatus(false);
        });
    }, []);

    useEffect(() => {
        load();
    }, [])

    return (
        <RequestsContext.Provider value={{
            loading: loadingStatus,
            loadRequests: load,
            requests: requests,
            setRequests: setRequests
        }}>
            {props.children}
        </RequestsContext.Provider>
    )


}