import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {Profile} from "@Types/Profile";


export interface IProfilesContext {
    profiles: Profile [];
    setProfiles: React.Dispatch<Profile[]>;
    loading: boolean;
    loadProfiles: () => Promise<void>;

}

export const ProfilesContext = React.createContext<IProfilesContext>({
    profiles: [],
    setProfiles: () => {},
    loadProfiles: () => Promise.resolve(),
    loading: false,
});

export const ProfilesProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const Api = useApi();

    const [profiles, setProfiles] = useState<Profile[]>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback(() => {
        setLoadingStatus(true);
        return Api.profile_call_getProfiles({}).then(res => {
            if (res.result === "ok") {
                setProfiles(res.profiles);
            } else {
                error(res.result);
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
        }).finally(() => {
            setLoadingStatus(false);
        });
    }, []);

    useEffect(() => {
        load();
    }, [])

    return (
        <ProfilesContext.Provider value={{
            loading: loadingStatus,
            loadProfiles: load,
            profiles: profiles,
            setProfiles: setProfiles
        }}>
            {props.children}
        </ProfilesContext.Provider>
    )


}