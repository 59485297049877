import React from "react";
import {AgencyList} from "@Pages/Home/HomeContent/Agencies/Agency/AgencyList";
import {Card} from "primereact/card";


export const Agency: React.FC = (props) => {

    return (
        <div className="Agency">
            <div className="fps__text--title mb-3">Gestion des agences</div>
            <div className="fps__text--description mb-5">Vous pouvez créer des agences
            </div>
            <Card>
                <AgencyList className={'p-2'}/>
            </Card>

        </div>
    )
}