import { callFunction } from "@Hooks/api";
import { useToast } from "@Hooks/toast";
import { User } from "@Types/User";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
	Auth,
	User as FirebaseUser,
	browserLocalPersistence,
	getAuth,
	onAuthStateChanged,
	setPersistence,
	signOut
} from "firebase/auth";
import { Firestore, collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { Functions, getFunctions } from "firebase/functions";
import { FirebaseStorage, getStorage } from "firebase/storage";
import Logrocket from 'logrocket';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { firebaseConfig } from "../config/firebase";

export interface IFirebaseContext {
	firestore: Firestore,
	app: FirebaseApp,
	functions: Functions,
	auth: Auth,
	storage: FirebaseStorage,
	user?: { firebase?: FirebaseUser, meta?: User },
}

export const FirebaseContext = React.createContext<IFirebaseContext | null>(null)

export const FirebaseProvider: React.FC<PropsWithChildren<{}>> = (props) => {

	const [app] = useState<FirebaseApp>(initializeApp(firebaseConfig));
	const [loading, setLoading] = useState<boolean>(true);

	const {error} = useToast();
	const [user, setUser] = useState<IFirebaseContext["user"]>(undefined);


    const context = useMemo(() => {
        const _context = {
            functions: getFunctions(app, 'europe-west3'),
            firestore: getFirestore(app),
            auth: getAuth(app),
            app: app,
            storage: getStorage(app),
        };

        void setPersistence(_context.auth, browserLocalPersistence);

		onAuthStateChanged(_context.auth, (user) => {
			if (user) {
				const collectionRef = collection(_context.firestore, 'users');
				const docRef = doc(collectionRef, user.uid);
				getDoc(docRef).then(async doc => {

					const meta = doc.data() as User;

					await callFunction(_context.functions, 'auth_call_checkAdmin', err => {
						if (err.code === "not-authorized" || err.code === "not-authenticated") {
							error("Vous ne pouvez pas accéder à cette page");
							signOut(_context.auth);
						}
					})({}).then(() => {
						setUser({
							meta, firebase: user
						});
						Logrocket.identify(user.uid, {
							name: `${meta.firstname} ${meta.lastname}`, email: user.email || '', role: meta.role,
						})
					});
				}).catch(err => {
					console.table(err);
					if (err.code === "permission-denied") {
						error('Vous ne pouvez pas accéder à cette page');
						signOut(_context.auth);
					}
				})
			} else {
				setUser({})
			}
			setLoading(false);
		});

		return _context;
	}, [app]);


	return (<FirebaseContext.Provider value={{...context, user}}>
		{!loading && user !== undefined && props.children}
	</FirebaseContext.Provider>)
}
