import React from "react";

import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {Tag} from "primereact/tag";

import {InputSwitch} from "primereact/inputswitch";
import {Order} from "@Types/Order";

import "./OrderDetail.scss";

export type OrderDetailProps = {
    visible: boolean
    setVisible: (visible: boolean) => void
    order: Order;
    updateOrder: (order: Order) => void;
    onHide: () => void;
}

export const OrderDetail: React.FC<OrderDetailProps> = (props) => {


    return (
        <div className={'OrderDetail'}>
            <Dialog style={{width: '1000px'}}

                    header={
                        <div className={classNames('text-xl',)}>
                            <Tag className={'uppercase'} severity={props.order.status ? 'success' : 'danger'}>
                                {props.order.status ? 'traité' : 'non traité'}
                            </Tag>
                        </div>
                    }
                    visible={props.visible}
                    onHide={() => {
                        props.onHide();
                    }}
                    draggable={false}
                    closeOnEscape
                    closable
                    dismissableMask
                    contentClassName="pb-5"
            >

                <div className={'flex flex-column'}>
                    <div className={'OrderDetail-header-title mb-2'}>
                        Commande #{props.order.orderUid.substring(0,6).toUpperCase()}
                    </div>

                    <div className={'text-lg font-semibold  OrderDetail-header-text mb-2'}>
                        Référence de la commande : {props.order.shipping_details.reference}
                    </div>

                    <div className={"mt-3 mb-6"}>
                        <div className={'p-field flex align-items-end'}>

                            <InputSwitch id={'statusChecked'} color={"green"} checked={props.order.status}
                                         onChange={(e) => {
                                             props.updateOrder({...props.order, status: e.value})
                                         }}/>
                            <label htmlFor={"statusChecked"} className={'text-lg  ml-2 OrderDetail-header-subtitle'}>
                                {props.order.status ? '  Commande  traitée' : 'Commande non traitée'}
                            </label>
                        </div>

                        <div className={'mt-5'}>

                            <div className={"flex"}>
                                <div>
                                    <div className={'text-lg font-semibold  OrderDetail-header-subtitle mb-2'}>
                                        Nom du client
                                    </div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text'}>{props.order.client.selectedAgency.name} ({props.order.client.selectedAgency.code})
                                    </div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text capitalize'}>{props.order.client.lastname} {props.order.client.firstname}</div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text '}>{props.order.client.email}</div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text '}>{props.order.client.phone}</div>
                                </div>

                                <div className={'OrderDetail-vertical-divider mx-4'}/>


                                <div>
                                    <div className={'text-lg font-semibold  OrderDetail-header-subtitle mb-2'}>
                                        Livraison
                                    </div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text'}>{props.order.shipping_details.name}
                                    </div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text capitalize'}>{props.order.shipping_details.phone}</div>
                                    <div
                                        className={'text-lg mb-1 OrderDetail-header-text '}>{props.order.shipping_details.pickup ? 'Enlèvement' : props.order.shipping_details.location}</div>
                                </div>

                            </div>


                        </div>

                    </div>


                    <div className={"mt-2 OrderDetail-grid"}>
                        {['Ref', 'Designation', 'Qté', 'Prix'].map((heard, index) =>(
                            <div key={index} className={'OrderDetail-grid-header'}>{heard}</div>
                        ))}

                        {props.order.items.map((cartItem, index)=>(
                           <React.Fragment key={index}>
                               <div className={'OrderDetail-grid-item'}>{cartItem.product.reference}</div>
                               <div className={'OrderDetail-grid-item'}>{cartItem.product.des}</div>
                               <div className={'OrderDetail-grid-item'}>{cartItem.quantity}</div>
                               <div className={'OrderDetail-grid-item'}>{new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'})
                                   .format(cartItem.product.price)}
                               </div>
                           </React.Fragment>
                        ))}
                    </div>

                </div>

            </Dialog>

        </div>
    )

}
