import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {Order} from "@Types/Order";


export interface IOrdersContext {
    orders: Order [];
    setOrders: React.Dispatch<Order[]>;
    loading: boolean;
    loadOrders: () => Promise<void>;

}

export const OrdersContext = React.createContext<IOrdersContext>({
    orders: [],
    setOrders: () => {
    },
    loadOrders: () => Promise.resolve(),
    loading: false,
});

export const OrdersProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const Api = useApi();

    const [orders, setOrders] = useState<Order[]>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback(() => {
        setLoadingStatus(true);
        return Api.order_call_getOrders({}).then(res => {
            if (res.result === "ok") {
                setOrders(res.orders);
            } else {
                error(res.result);
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
        }).finally(() => {
            setLoadingStatus(false);
        });
    }, []);

    useEffect(() => {
        load();
    }, [])

    return (
        <OrdersContext.Provider value={{
            loading: loadingStatus,
            loadOrders: load,
            orders: orders,
            setOrders: setOrders
        }}>
            {props.children}
        </OrdersContext.Provider>
    )


}