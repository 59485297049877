import React, {useContext} from "react";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {useToast} from "@Hooks/toast";
import {Column} from "primereact/column";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import {CustomTable} from "@Components/CustomTable";
import {AgencyRequestsContext} from "../../../../Contexts/AgencyRequestsContext";
import {AgencyRequest} from "@Types/AgencyRequest";
import {DateTime} from "luxon";
import {UserTag} from "@Components/UserTag";


export const AgencyRequestList: React.FC = () => {


    const {loading, loadAgencyRequests, agencyRequests} = useContext(AgencyRequestsContext);

    const Api = useApi();

    const [deleteAgencyRequest, deleteAgencyRequestMap] = usePromise(async (request: AgencyRequest) => {
        try {
            await Api.agencyrequest_call_deleteAgencyRequest({agencyRequest: request});
        } catch (err) {
            console.table(err);
        } finally {
            await loadAgencyRequests();
        }
    });

    const {promise} = useToast();

    return (
        <div className={classNames(["AgencyRequestList p-card "])}>
            <ConfirmDialog/>
            <CustomTable
                title={"Liste des demandes agence"}
                headerSearchFields={["client.firstname", "client.lastname", 'agency_code', 'client.email']}
                value={agencyRequests}
                loading={loading}
                emptyMessage={"Aucune demande trouvée"}
            >
                <Column header={"Nom de l'utilisateur"}
                        sortable sortField={"firstname"}
                        body={(data: AgencyRequest) => <UserTag full user={data.client} />}
                />

                <Column header={"Date"} sortable field={'time'} sortField={"time"}
                        body={(data: AgencyRequest) => `${DateTime.fromJSDate(new Date(data.createdAt)).toFormat('dd/MM/yyyy')}`}
                />
                <Column header={"Code client"} field="agency_code" sortable/>

                <Column header={"Action"} alignHeader="center" body={(data: AgencyRequest) => {
                    return (
                        <div className={"flex justify-content-center"}>
                            <Button
                                onClick={() => {
                                    confirmDialog({
                                        header: "Supprimer une demande ",
                                        message: "Êtes-vous sûr de vouloir supprimer la demande de l'utilisateur ? Cette action est définitive",
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: "Valider la suppression",
                                        rejectLabel: "Annuler",
                                        rejectClassName: 'p-button-secondary',
                                        closable: false,
                                        accept: async () => {
                                            await promise(deleteAgencyRequest(data.agencyRequestUid)(data), {
                                                pending: "Suppression de l'utilisateur en cours ...",
                                                success: "Demaande supprimée !"
                                            });
                                        },
                                    });
                                }}
                                loading={deleteAgencyRequestMap.get(data.agencyRequestUid)}
                                className={'p-button p-button-warning p-button-rounded'}
                                icon={'pi pi-trash'}/>
                        </div>
                    )
                }}/>
            </CustomTable>
        </div>
    )
}
