import React from "react";
import {PageHeader} from "@Components/PageHeader";
import {LoginForm} from "@Pages/Auth/LoginForm";
import {ForgottenPassword} from "@Pages/Auth/ForgottenPassword";

export type AuthProps = {
    isLogin?: boolean;
}
export const Auth: React.FC<AuthProps> = props => {

    return (
        <div className="Login flex flex-column align-items-center">
            <PageHeader/>
            {props.isLogin ? <LoginForm/> : <ForgottenPassword/>}
        </div>
    )
}
