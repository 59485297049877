import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {Agency} from "@Types/Agency";


export interface IAgenciesContext {
    agencies: Agency [];
    setAgencies: React.Dispatch<Agency[]>;
    loading: boolean;
    loadAgencies: () => Promise<void>;

}

export const AgenciesContext = React.createContext<IAgenciesContext>({
    loadAgencies: () => Promise.resolve(),
    setAgencies: () => {
    },
    loading: false,
    agencies: []
});

export const AgenciesProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const Api = useApi();

    const [agencies, setAgencies] = useState<Agency[]>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const {error} = useToast();

    const load = useCallback(() => {
        setLoadingStatus(true);
        return Api.agency_call_getAgencies({}).then(res => {
            if (res.result === "ok") {
                setAgencies(res.agencies);
            } else {
                error(res.result);
            }
        }).catch(err => {
            console.table(err);
            error('Une erreur interne est survenue');
        }).finally(() => {
            setLoadingStatus(false);
        });
    }, []);

    useEffect(() => {
        load();
    }, [])

    return (
        <AgenciesContext.Provider value={{
            loading: loadingStatus,
            loadAgencies: load,
            agencies: agencies,
            setAgencies: setAgencies
        }}>
            {props.children}
        </AgenciesContext.Provider>
    )


}
