import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import "./CustomTable.scss";
import {DataTable, DataTableRowClickEventParams} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {FilterMatchMode} from "primereact/api";
import Fuse from "fuse.js";
import {Dropdown} from "primereact/dropdown";
import IFuseOptions = Fuse.IFuseOptions;

export interface CustomTableProps {
    title: string;
    headerSearchFields?: string[];
    headerDropdown?: boolean;
    headerDropdownItems?: any[];
    headerDropdownItem?: any;
    headerDropdownPlaceholder?: string;
    onHeaderDropdownChange?: (value: any) => void;
    headerButton?: boolean;
    headerButtonLabel?: string;
    headerButtonLoading?: boolean;
    headerButtonIcon?: string;
    headerButtonAction?: React.MouseEventHandler<HTMLButtonElement>;
    headerButton2?: boolean;
    headerButtonLabel2?: string;
    headerButtonLoading2?: boolean;
    headerButtonIcon2?: string;
    headerButtonAction2?: React.MouseEventHandler<HTMLButtonElement>;
    emptyMessage?: string;
    value: any[];
    loading?: boolean;
    expandedRowsTemplate?: (data: any) => React.ReactElement;
    selection?: any[]
    onSelect?: (selection?: any) => void;
    pagination?: boolean;
    sortField?: string;
    sortOrder?:  1 | 0 | -1 | undefined | null;
    onRowClick?: (e: DataTableRowClickEventParams) => void;
}

export const CustomTable: React.FC<PropsWithChildren<CustomTableProps>> = propsDefault => {

    const props = useMemo(() => ({
        pagination: true,
        ...propsDefault,
    }), [propsDefault])

    const [headerFilter, setHeaderFilter] = useState<string>('');
    const [expandedRows, setExpandedRows] = useState<any>();
    const [filters, setFilters] = useState({});
    const [items, setItems] = useState(props.value);

    const filterPropsValues = useCallback(() => {
        const filterField = props.headerSearchFields;
        if (filterField !== undefined) {
            const options: IFuseOptions<any> = {
                keys: filterField,
                threshold: 0.3,
            }
            const fuse = new Fuse(props.value, options);
            const result = fuse.search(headerFilter).map(e => e.item);
            setItems(headerFilter.length === 0 ? props.value : result);
        }
    }, [props.headerSearchFields, props.value, headerFilter])

    useEffect(() => {
        filterPropsValues()
    }, [filterPropsValues]);

    useEffect(() => {
        if (headerFilter)
            filterPropsValues()
    }, [headerFilter, filterPropsValues]);

    useEffect(() => {
        setFilters({
            'status': {value: null, matchMode: FilterMatchMode.EQUALS}
        })
    }, [items]);


    const header = <div className="CustomTable__header flex justify-content-center align-items-center">
        <div className="fps__text--medium mr-auto">
            {props.title}
        </div>
        {
            props.headerDropdown &&
            <Dropdown editable value={props.headerDropdownItem} options={props.headerDropdownItems}
                      onChange={e => props.onHeaderDropdownChange?.(e.value)}
                      placeholder={props.headerDropdownPlaceholder} className="mr-3"/>
        }
        {
            props.headerSearchFields &&
            <span className="p-input-icon-left CustomTable__header--input">
                <i className="pi pi-search"/>
                <InputText value={headerFilter} onChange={e => setHeaderFilter(e.target.value)}
                           placeholder="Rechercher"/>
            </span>
        }
        {
            props.headerButton && <div className="CustomTable__header--action ml-3">
                <Button loading={props.headerButtonLoading} icon={props.headerButtonIcon} label={props.headerButtonLabel}
                        onClick={props.headerButtonAction}/>
            </div>
        }
        {
            props.headerButton2 && <div className="CustomTable__header--action ml-2">
                <Button loading={props.headerButtonLoading2} icon={props.headerButtonIcon2} label={props.headerButtonLabel2}
                        onClick={props.headerButtonAction2}/>
            </div>
        }
    </div>;

    return (
        <DataTable
            header={header}
            loading={props.loading}
            value={items}
            expandedRows={expandedRows}
            onRowToggle={e => {
                 setExpandedRows(e.data)
            }}
            rowExpansionTemplate={props.expandedRowsTemplate}
            removableSort
            sortMode="multiple"
            rowHover
            sortField={props.sortField}
            sortOrder={props.sortOrder}
            rows={10}
            onRowClick={props.onRowClick}
            filters={filters}
            onSelectionChange={e => props.onSelect?.(e.value)}
            selection={props.selection}
            rowsPerPageOptions={[5, 10, 15, 20]}
            paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'}
            currentPageReportTemplate={`Montre résultats {first} à {last} sur {totalRecords}`}
            paginator={props.pagination}
            emptyMessage={props.emptyMessage || "Aucun résultat trouvé"}
        >
            {props.selection && <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>}
            {props.expandedRowsTemplate && <Column expander headerStyle={{width: '3em'}}/>}
            {props.children}
        </DataTable>
    )
}
