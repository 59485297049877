import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import "./UserForm.scss";
import {User} from "@Types/User";
import {Agency} from "@Types/Agency";
import {useFormik} from "formik";
import {v4} from "uuid";
import {useToast} from "@Hooks/toast";
import {usePromise} from "@Hooks/promise";
import {userSchema} from "@Schemas/user.schema";
import {CustomDialog} from "@Components/CustomDialog";
import {Input} from "@Components/Input";
import {CustomSelect} from "@Components/CustomSelect";
import {useApi} from "@Hooks/api";
import {UsersContext} from "../../../../../Contexts/UsersContext";
import {AgenciesContext} from "../../../../../Contexts/AgenciesContext";
import {InputSwitch} from "primereact/inputswitch";

export interface UserFormProps {
    isEdit?: boolean;
    users: User[];
    selectedUser?: User;
    selectedAgency?: Agency;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

export const UserForm: React.FC<UserFormProps> = props => {

    const {promise} = useToast();

    const groups = useMemo(() => {
        return Array.from(new Set(props.users.map(e => e.groups).flat()))
    }, [props.users]);

    const {loadAgencies, agencies} = useContext(AgenciesContext);

    useEffect(() => {
        loadAgencies();
    }, []);

    const emptyUser: User = useMemo(() => ({
        role: 'client',
        email: '',
        uuid: v4(),
        lastname: '',
        verified: false,
        phone: '',
        firstname: '',
        groups: [],
        userUid: '',
        agencies: [],
        createdAt: '',
        disabled :false,
        selectedAgency: {
            name: '',
            address: '',
            phone: '',
            code: '',
            email: '',
            agencyUid: '',
        },
        custom_addresses: []
    }), [])

    const [user, setUser] = useState<User>(emptyUser);
    const {loadUsers} = useContext(UsersContext);

    const Api = useApi();

    useEffect(() => {
        if (props.selectedUser) {
            console.log(props.selectedUser);
            setUser(props.selectedUser);
        }
        else
            setUser(emptyUser);
    }, [emptyUser, props.selectedUser]);

    const [createUser, createUserMap] = usePromise(async (data: User) => {
        try {
            if (!props.isEdit) {
                const res = await Api.user_call_createClient({
                    ...data
                });
                if (res.result !== "ok") throw new Error(res.result)
            } else if (props.selectedUser && props.isEdit) {
                const res = await Api.user_call_updateClient({
                    ...data,
                    selectedAgency: data.agencies[0]
                });
                if (res.result !== "ok") throw new Error(res.result)
            }
        } finally {
            await loadUsers()
            userFormik.resetForm();
        }
    });

    const userFormik = useFormik({
        initialValues: user,
        isInitialValid: userSchema.isValidSync(user),
        validationSchema: userSchema,
        enableReinitialize: true,
        onSubmit: onSave
    })

    function onCancel() {
        props.setVisible(false)
        userFormik.resetForm();
    }

    function onSave(values: User) {
        promise(createUser(values.userUid)(values), {
            pending: props.isEdit ? "Mise à jour en cours ..." : "Création en cours ...",
            success: props.isEdit ? "Informations de l'utilisateur modifiées !" : "Utilisateur correctement créé !",
            error: {
                type: "error",
                render: (err: any) => {
                    return <p>{err.data?.message}</p>
                }
            }
        }).finally(() => {
            props.setVisible(false);
        });
    }

    const groupsCompleteMethod = useCallback((query: string) => {
        return groups.filter(e => new RegExp(query, 'i').test(e));
    }, [groups]);

    const agencyCompleteMethod = useCallback((query: string) => {
        return agencies.filter(e => new RegExp(query, 'i').test(e.name) || new RegExp(query, 'i').test(e.code))
    }, [agencies])

    return (
        <CustomDialog
            visible={props.visible}
            setVisible={props.setVisible}
            defaultCancel={true}
            title={props.isEdit ? "Modifier un utilisateur" : "Ajouter un utilisateur"}
            acceptLabel={props.isEdit ? "Sauvegarder les changements" : "Envoyer l'invitation d'inscription"}
            cancelLabel={"Annuler"}
            onAccept={userFormik.submitForm}
            disabledAccept={!userFormik.isValid}
            acceptLoading={createUserMap.get(userFormik.values.userUid)}
            onCancel={onCancel}
        >
            <form className="UserForm" onSubmit={userFormik.handleSubmit}>
                <Input className="UserForm__input--half" icon={false}
                       onChange={val => userFormik.setFieldValue('lastname', val, true)}
                       value={userFormik.values.lastname} name={"lastname"} label={"Nom"}/>
                <Input className="UserForm__input--half" icon={false}
                       onChange={val => userFormik.setFieldValue('firstname', val, true)}
                       value={userFormik.values.firstname} name={"firstname"} label={"Prénom"}/>
                <Input className="UserForm__input--half" icon={"pi pi-at"}
                       disabled={props.isEdit}
                       onChange={val => userFormik.setFieldValue('email', val, true)} value={userFormik.values.email}
                       name={"mail"} label={"Email"}/>
                <Input className="UserForm__input--half" icon={"pi pi-phone"}
                       onChange={val => userFormik.setFieldValue('phone', val, true)} value={userFormik.values.phone}
                       name={"phone"} label={"Téléphone"}/>
                <CustomSelect
                    value={userFormik.values.groups}
                    suggestions={groups}
                    completeMethod={groupsCompleteMethod}
                    className="UserForm__input--full"
                    label={"Groupes"}
                    upsert
                    onChange={val => userFormik.setFieldValue('groups', val.value, true)}
                />

                <CustomSelect
                    value={userFormik.values.agencies}
                    suggestions={agencies}
                    completeMethod={agencyCompleteMethod}
                    field="name"
                    className="UserForm__input--full"
                    label={"Agences"}
                    itemTemplate={(e: Agency) => {
                        return <div>
                            {e.code} - {e.name}
                        </div>
                    }}
                    onChange={val => userFormik.setFieldValue('agencies', val.value, true)}
                />

                <div className={'p-field flex align-items-center mt-2'}>
                    <InputSwitch id={'statusChecked fps__text--label'} color={"green"} checked={userFormik.values.disabled}
                                 onChange={(e) => userFormik.setFieldValue('disabled', e.value)}/>
                    <label htmlFor={"statusChecked"} className={'ml-2 fps__text--label'}>
                        {userFormik.values.disabled ? 'Compte désactivé' : 'Compte actif'  }
                    </label>
                </div>

            </form>
        </CustomDialog>)
}

