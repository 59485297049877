import React from "react";
import {OrdersProvider} from "../../../Contexts/OrdersContext";
import {OrderList} from "@Pages/Home/HomeContent/Orders/OrderList";
import {Card} from "primereact/card";


export const Orders: React.FC = (props) => {


    return (
        <div className="Orders">
            <div className="fps__text--title mb-3">Commandes</div>
            <div className="fps__text--description mb-5">Vous retrouvez ici la liste des commandes qui ont été
                effectuées.
            </div>
            <Card>
                <OrderList className={'p-2'}/>
            </Card>
        </div>
    )
}
