import * as yup from "yup";
import {agencySchema} from "@Schemas/agency.schema";

export const userSchema = yup.object().shape({
    email: yup.string().email().required("L'adresse mail est obligatoire"),
    lastname: yup.string().required("Le prénom est obligatoire"),
    firstname: yup.string().required("Le nom de famille est obligatoire"),
    phone: yup.string().required("Le numéro de téléphone est obligatoire"),
    groups: yup.array().of(yup.string()).ensure(),
    agencies: yup.array().of(agencySchema).min(1, "Vous devez sélectionner au moin une agence").ensure()
})
